/* WatermarkSettingsPage.css */

@font-face {
  font-family: 'Helvetica Now Display Bold Italic';
  src: url('../../font/HelveticaNowDisplay-BoldIta.eot');
  src: local('Helvetica Now Display Bold Ita'), local('HelveticaNowDisplay-BoldIta'),
      url('../../font/HelveticaNowDisplay-BoldIta.eot?#iefix') format('embedded-opentype'),
      url('../../font/HelveticaNowDisplay-BoldIta.woff2') format('woff2'),
      url('../../font/HelveticaNowDisplay-BoldIta.woff') format('woff'),
      url('../../font/HelveticaNowDisplay-BoldIta.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Now Display Regular';
  src: url('../../font/HelveticaNowDisplay-RegIta.eot');
  src: local('Helvetica Now Display Reg Ita'), local('HelveticaNowDisplay-RegIta'),
      url('../../font/HelveticaNowDisplay-RegIta.eot?#iefix') format('embedded-opentype'),
      url('../../font/HelveticaNowDisplay-RegIta.woff2') format('woff2'),
      url('../../font/HelveticaNowDisplay-RegIta.woff') format('woff'),
      url('../../font/HelveticaNowDisplay-RegIta.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Now Display Medium Italic';
  src: url('../../font/HelveticaNowDisplay-MedIta.eot');
  src: local('Helvetica Now Display Med Ita'), local('HelveticaNowDisplay-MedIta'),
      url('../../font/HelveticaNowDisplay-MedIta.eot?#iefix') format('embedded-opentype'),
      url('../../font/HelveticaNowDisplay-MedIta.woff2') format('woff2'),
      url('../../font/HelveticaNowDisplay-MedIta.woff') format('woff'),
      url('../../font/HelveticaNowDisplay-MedIta.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Now Display Bold';
  src: url('../../font/HelveticaNowDisplay-Bold.eot');
  src: local('Helvetica Now Display Bold'), local('HelveticaNowDisplay-Bold'),
      url('../../font/HelveticaNowDisplay-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../font/HelveticaNowDisplay-Bold.woff2') format('woff2'),
      url('../../font/HelveticaNowDisplay-Bold.woff') format('woff'),
      url('../../font/HelveticaNowDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Now Display Regular';
  src: url('../../font/HelveticaNowDisplay-Regular.eot');
  src: local('Helvetica Now Display Regular'), local('HelveticaNowDisplay-Regular'),
      url('../../font/HelveticaNowDisplay-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../font/HelveticaNowDisplay-Regular.woff2') format('woff2'),
      url('../../font/HelveticaNowDisplay-Regular.woff') format('woff'),
      url('../../font/HelveticaNowDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Now Display Medium';
  src: url('../../font/HelveticaNowDisplay-Medium.eot');
  src: local('Helvetica Now Display Medium'), local('HelveticaNowDisplay-Medium'),
      url('../../font/HelveticaNowDisplay-Medium.eot?#iefix') format('embedded-opentype'),
      url('../../font/HelveticaNowDisplay-Medium.woff2') format('woff2'),
      url('../../font/HelveticaNowDisplay-Medium.woff') format('woff'),
      url('../../font/HelveticaNowDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Atak Medium';
  src: url('../../font/AtakMedium.woff2');
  src: local('Atak Medium'), local('AtakMedium'),
      url('../../font/AtakMedium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Atak Regular';
  src: url('../../font/AtakRegular.woff2');
  src: local('Atak Regular'), local('AtakRegular'),
      url('../../font/AtakRegular.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Atak Bold';
  src: url('../../font/AtakBold.woff2');
  src: local('Atak Bold'), local('AtakBold'),
      url('../../font/AtakBold.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

.watermark-settings-page {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px;
    background-color: #fff;
    border-radius: 20px;
  }
  
  .left-section {
    flex: 0.4;
    padding-right: 20px;
  }

  .left-section h2{
    font-size: 25px;
    font-weight: 700;
    color: #0071C2;
    margin: 0;
    margin-bottom: 30px;
  }
  
  .right-section {
    flex: 0.6;
  }
  
  .watermark-type-options {
    margin-bottom: 10px;
    margin-top: 30px;
  }
  
  .watermark-type-options label {
    display: block;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
  }
  
  .watermark-type-options input[type="radio"] {
    display: none; /* Hide default radio buttons */
  }

  .watermark-settings-page .custom_text{
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 16px 20px;
    height: auto;
    font-size: 14px;
    font-weight: 400;
    border-radius: 7px;
    color: #555;
    height: 50px;
    width: 100%;
  }
  
  /* Custom radio button design */
  /* .watermark-type-options label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border: 2px solid #007bff;
    border-radius: 50%;
    background-color: #fff;
  } */

  .watermark-settings-page .setting_radio_button_block .css-1hbvpl3-MuiSvgIcon-root, .setting_radio_button_block .css-11zohuh-MuiSvgIcon-root{
    height: 2.5rem;
    width: 2.5rem;
    fill: #0071C2;
  }

  .watermark-settings-page .setting_radio_button_block .css-vqmohf-MuiButtonBase-root-MuiRadio-root:hover{
    background-color: transparent;
  }

  .watermark-settings-page .setting_radio_button_block span{
    font-size: 14px;
    font-weight: 700;
    color: #555;
    font-family: 'Atak Regular';
  }
  
  .watermark-type-options input[type="radio"]:checked + label::before {
    background-color: #007bff; /* Color when checked */
  }
  
  /* Watermark type option label text */
  /* .watermark-type-options label span {
    position: relative;
    top: -2px;
    margin-left: 10px;
  } */
  
  .image-watermark-options,
  .text-watermark-options {
    margin-bottom: 20px;
  }
  
  .image-watermark-options .css-14pt78w-MuiSlider-rail{
    height: 5px;
    background-color: #e9e9e9;
    opacity: 1;
  }

  .image-watermark-options .css-1gv0vcd-MuiSlider-track{
    background-color: #5EAC18;
    border-color: #5EAC18;
  }

  .image-watermark-options .css-jx1sa5-MuiSlider-thumb{
    background-color: #fff;
    border: 2px solid #5EAC18;
  }

  /* Other styles remain unchanged */
  
  /* WatermarkSettingsPage.css */
  
  /* Keep .watermark-settings-page and other styles unchanged */
  
  .image-container {
    position: relative;
    width: 100%; /* Adjust width as needed */
    margin: 0 auto; /* Center the container */
  }
  
  .preview-image {
    width: 100%;
    display: block;
  }
  
  .touchpoints {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center align touchpoints */
  }
  
  .touchpoint {
    width: 20px; /* Adjusted smaller width */
    height: 20px; /* Adjusted smaller height */
    margin: 5px;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: #f0f0f0;
    border-radius: 50%; /* Rounded shape */
    position: absolute;
  }

  .watermark-settings-page .links {
    font-size: 14px !important;
    font-weight: 500;
    letter-spacing: 0.2px;
    padding: 14px 18px;
    display: inline-block;
    border: 1px solid #0071C2 !important;
    background-color: #fff !important;
    border-radius: 50px !important;
    text-transform: uppercase;
    color: #555 !important;
    box-shadow: none !important;
    font-family: "Helvetica Now Display Medium";
  }

    .watermark-settings-page .links:hover {
        background-color: #0071C2 !important;
        color: #fff !important;
    }
  
  /* Specific position classes */
  .top-left {
    top: 10%;
    left: 10%;
  }
  
  .top-center {
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .top-right {
    top: 10%;
    right: 10%;
  }
  
  .center-left {
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
  }
  
  .center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .center-right {
    top: 50%;
    right: 10%;
    transform: translateY(-50%);
  }
  
  .bottom-left {
    bottom: 10%;
    left: 10%;
  }
  
  .bottom-center {
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .bottom-right {
    bottom: 10%;
    right: 10%;
  }
  
  /* Watermark preview */
  .watermark-preview {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.5; /* Default opacity */
    max-width: 600%; /* Ensure watermark image does not exceed its container */
    max-height: 600%; /* Ensure watermark image does not exceed its container */
    width: 380px; /* Allow watermark image to adjust width as needed */
    height: auto; /* Allow watermark image to adjust height as needed */
  }
  