@font-face {
  font-family: "Helvetica Now Display Bold Italic";
  src: url("../font/HelveticaNowDisplay-BoldIta.eot");
  src: local("Helvetica Now Display Bold Ita"), local("HelveticaNowDisplay-BoldIta"), url("../font/HelveticaNowDisplay-BoldIta.eot?#iefix") format("embedded-opentype"), url("../font/HelveticaNowDisplay-BoldIta.woff2") format("woff2"), url("../font/HelveticaNowDisplay-BoldIta.woff") format("woff"), url("../font/HelveticaNowDisplay-BoldIta.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Helvetica Now Display Regular";
  src: url("../font/HelveticaNowDisplay-RegIta.eot");
  src: local("Helvetica Now Display Reg Ita"), local("HelveticaNowDisplay-RegIta"), url("../font/HelveticaNowDisplay-RegIta.eot?#iefix") format("embedded-opentype"), url("../font/HelveticaNowDisplay-RegIta.woff2") format("woff2"), url("../font/HelveticaNowDisplay-RegIta.woff") format("woff"), url("../font/HelveticaNowDisplay-RegIta.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Helvetica Now Display Medium Italic";
  src: url("../font/HelveticaNowDisplay-MedIta.eot");
  src: local("Helvetica Now Display Med Ita"), local("HelveticaNowDisplay-MedIta"), url("../font/HelveticaNowDisplay-MedIta.eot?#iefix") format("embedded-opentype"), url("../font/HelveticaNowDisplay-MedIta.woff2") format("woff2"), url("../font/HelveticaNowDisplay-MedIta.woff") format("woff"), url("../font/HelveticaNowDisplay-MedIta.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Helvetica Now Display Bold";
  src: url("../font/HelveticaNowDisplay-Bold.eot");
  src: local("Helvetica Now Display Bold"), local("HelveticaNowDisplay-Bold"), url("../font/HelveticaNowDisplay-Bold.eot?#iefix") format("embedded-opentype"), url("../font/HelveticaNowDisplay-Bold.woff2") format("woff2"), url("../font/HelveticaNowDisplay-Bold.woff") format("woff"), url("../font/HelveticaNowDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Now Display Regular";
  src: url("../font/HelveticaNowDisplay-Regular.eot");
  src: local("Helvetica Now Display Regular"), local("HelveticaNowDisplay-Regular"), url("../font/HelveticaNowDisplay-Regular.eot?#iefix") format("embedded-opentype"), url("../font/HelveticaNowDisplay-Regular.woff2") format("woff2"), url("../font/HelveticaNowDisplay-Regular.woff") format("woff"), url("../font/HelveticaNowDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Now Display Medium";
  src: url("../font/HelveticaNowDisplay-Medium.eot");
  src: local("Helvetica Now Display Medium"), local("HelveticaNowDisplay-Medium"), url("../font/HelveticaNowDisplay-Medium.eot?#iefix") format("embedded-opentype"), url("../font/HelveticaNowDisplay-Medium.woff2") format("woff2"), url("../font/HelveticaNowDisplay-Medium.woff") format("woff"), url("../font/HelveticaNowDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Atak Medium";
  src: url("../font/AtakMedium.woff2");
  src: local("Atak Medium"), local("AtakMedium"), url("../font/AtakMedium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Atak Regular";
  src: url("../font/AtakRegular.woff2");
  src: local("Atak Regular"), local("AtakRegular"), url("../font/AtakRegular.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Atak Bold";
  src: url("../font/AtakBold.woff2");
  src: local("Atak Bold"), local("AtakBold"), url("../font/AtakBold.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
body {
  color: #555;
  font-family: Atak Regular;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  font-family: "Helvetica Now Display Bold";
}
body a {
  color: #555;
  font-family: "Helvetica Now Display Medium";
  cursor: pointer;
  text-decoration: none !important;
  transition: all 0.35s ease-in-out;
}
body a:hover {
  color: #0071C2;
  text-decoration: none;
  font-weight: 400;
}
body .autorenew_check {
  margin-bottom: 10px;
}
body .autorenew_check .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0;
  padding-right: 5px;
}
body .autorenew_check .css-i4bv87-MuiSvgIcon-root {
  font-size: 1.9rem;
  padding: 0;
}
body .autorenew_check span {
  font-size: 14px;
  font-family: "Atak Regular";
  padding-left: 0;
}
body .custom_file_group {
  height: 50px !important;
}
body .custom_file_group .custom-file-label {
  padding: 13px 20px 14px 20px !important;
}
body .custom_file_group .custom-file-label::after {
  top: 10px !important;
  font-size: 12px !important;
}
body .static_refresh img {
  animation: none;
}
body .main_title {
  color: #0071C2;
  font-family: "Helvetica Now Display Bold";
  font-size: 25px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 30px;
}
body .animated_refresh img {
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
body input::-moz-placeholder {
  color: #555;
  opacity: 1;
}
body input::placeholder {
  color: #555;
  opacity: 1;
}
body .MuiTooltip-popper[data-popper-placement*=bottom] .css-1k51tf5-MuiTooltip-tooltip {
  margin-top: 3px;
}
body .logo_note {
  margin: 0 !important;
  margin-top: 5px !important;
  color: #0071C2 !important;
}
body .css-1k51tf5-MuiTooltip-tooltip {
  font-size: 12px;
  background-color: #fff;
  border: 1px solid black;
  color: #555;
  border-radius: 0;
}
body .css-1k51tf5-MuiTooltip-tooltip .css-kudwh-MuiTooltip-arrow {
  display: none;
}
body .blue_text {
  color: #0071C2;
}
body .button_block {
  text-align: right;
  padding-bottom: 20px;
}
body .button_block .links {
  margin-left: 15px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  padding: 14px 18px;
  display: inline-block;
  border: 1px solid #0071C2;
  background-color: transparent;
  border-radius: 50px;
  text-transform: uppercase;
}
body .button_block .links:hover {
  background-color: #0071C2;
  color: #fff;
}
body .drop_menu {
  margin-top: 15px;
}
body .drop_menu ul {
  padding: 15px;
  border-radius: 10px;
  color: #555;
}
body .drop_menu ul li {
  font-size: 14px;
  font-family: 400;
  color: #555;
  font-family: "Helvetica Now Display Regular";
}
body .drop_menu ul li.divider {
  height: 1px;
  margin: 5px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
body .drop_menu ul li:hover {
  color: #0071C2;
}
body .drop_menu ul .material-icons {
  margin-right: 7px;
  margin-top: 2px;
  font-size: 20px;
}
body .validation_notes {
  font-size: 12px;
  font-weight: 400;
  color: #EC1A25;
  margin: 0;
  margin-top: 5px;
  display: block;
}
body .counter_text {
  font-size: 12px;
  font-weight: 400;
  color: #5EAC18;
  margin: 0;
  margin-top: 5px;
  display: block;
  text-align: end;
}
body .search-bar {
  top: 0;
}
body .search-bar input[type=text] {
  padding: 31px 60px 31px 56px;
}
body .search-bar .search-icon {
  top: 26px;
}
body .search-bar .close-search {
  top: 22px;
}
body .search_bar {
  position: relative;
}
body .search_bar fieldset {
  border: 1px solid #ddd;
}
body .search_close {
  display: block;
  padding: 0;
  min-width: auto;
  position: absolute;
  right: 30px;
  top: 30px;
}
body .search_close img {
  width: 20px;
}
body .search_close:hover {
  background-color: transparent;
}
body .drop_menu {
  display: inline-block;
  padding: 0;
  min-width: auto;
  color: #555;
}
body .drop_menu:hover {
  background-color: transparent;
}
body .d-flex {
  display: flex;
}
body .align-self-center {
  align-self: center;
}
body .text-center {
  text-align: center;
}
body .text-right {
  text-align: right !important;
}
body .no-gutter {
  margin-right: 0;
  margin-left: 0;
}
body .no-gutter > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}
body .drop_menus {
  font-size: 14px;
  font-style: normal;
}
body .drop_menus .placeholder {
  color: #555;
  font-style: normal;
}
body iframe {
  border: none;
}
body .error_class {
  background-color: #fff;
  padding: 30px;
  border-radius: 0;
  margin-bottom: 0;
}
body .error_class h2 {
  font-size: 40px;
  font-weight: 700;
  color: #0071C2;
  margin-bottom: 30px;
}
body .error_class p {
  font-size: 25px;
  font-weight: 700;
  font-family: "Helvetica Now Display Bold";
  color: #555;
  line-height: 40px;
  margin: 30px auto;
  max-width: 750px;
}
body .error_class .button_block {
  text-align: center;
}
body .error_class .button_block .links {
  margin-left: 15px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  padding: 14px 18px;
  display: inline-block;
  border: 1px solid #0071C2;
  background-color: #fff;
  border-radius: 50px;
  text-transform: uppercase;
}
body .error_class .button_block .links:hover {
  background-color: #0071C2;
  color: #fff;
}
body .dialog_box_popup .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 10px;
}
body .dialog_box_popup .dialog_box_block .dialog_box_title {
  padding: 20px 30px;
  background-color: #0071C2;
  display: flex;
  justify-content: space-between;
  margin: 0;
}
body .dialog_box_popup .dialog_box_block .dialog_box_title h6 {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin: auto 0;
  margin-right: 70px;
}
body .dialog_box_popup .dialog_box_block .dialog_box_title button {
  min-width: 25px;
  max-width: 100%;
  padding: 0;
}
body .dialog_box_popup .dialog_box_block .dialog_box_detail {
  padding: 20px 30px;
  margin: 0;
}
body .dialog_box_popup .dialog_box_block .dialog_box_detail p {
  font-size: 16px;
  font-weight: 400;
  color: #555;
  margin: 0;
}
body .dialog_box_popup .dialog_box_button {
  padding: 0 30px 20px 30px;
  text-align: right;
}
body .dialog_box_popup .dialog_box_button button {
  margin-left: 15px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Helvetica Now Display Medium";
  letter-spacing: 0.2px;
  padding: 14px 18px;
  display: inline-block;
  border: 1px solid #0071C2;
  background-color: #fff;
  border-radius: 50px;
  text-transform: uppercase;
}
body .dialog_box_popup .dialog_box_button button:hover {
  background-color: #0071C2;
  color: #fff;
}
body .login_block {
  display: block;
  background-color: #fff;
}
body .login_block .back_block {
  padding: 50px 100px;
}
body .login_block .back_block .arrow_block {
  display: flex;
  justify-content: space-between;
}
body .login_block .back_block .arrow_block .back_arrow {
  width: 40px;
  height: 40px;
  display: block;
  border: 1px solid #0071C2;
  border-radius: 50%;
  position: relative;
}
body .login_block .back_block .arrow_block .back_arrow img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
body .login_block .back_block .arrow_block p {
  font-size: 14px;
  font-weight: 400;
  color: #555;
  margin: auto 0;
}
body .login_block .back_block .arrow_block p a {
  color: #0071C2;
  font-weight: 500;
  font-family: "Atak Medium";
}
body .login_block .back_block .login_title_block {
  padding: 50px 0;
}
body .login_block .back_block .login_title_block figure {
  margin-bottom: 30px;
}
body .login_block .back_block .login_title_block h1 {
  font-size: 50px;
  font-weight: 700;
  color: #0071C2;
  margin: 0;
  margin-bottom: 10px;
}
body .login_block .back_block .login_title_block p {
  font-size: 14px;
  font-weight: 400;
  color: #555;
  margin: 0;
  margin-bottom: 50px;
}
body .login_block .back_block .login_title_block .form_block .row {
  margin-left: -5px;
  margin-right: -5px;
}
body .login_block .back_block .login_title_block .form_block .row .col-md-3,
body .login_block .back_block .login_title_block .form_block .row .col-sm-4,
body .login_block .back_block .login_title_block .form_block .row .col-xs-6 {
  padding-left: 5px;
  padding-right: 5px;
}
body .login_block .back_block .login_title_block .form_block .input-group {
  margin-bottom: 40px;
}
body .login_block .back_block .login_title_block .form_block .input-group.icon_field {
  position: relative;
}
body .login_block .back_block .login_title_block .form_block .input-group.icon_field .eye_icon {
  top: 8px;
  right: 15px;
  position: absolute;
  z-index: 2;
}
body .login_block .back_block .login_title_block .form_block .input-group .input-group-addon {
  vertical-align: top;
}
body .login_block .back_block .login_title_block .form_block .input-group input[type=text],
body .login_block .back_block .login_title_block .form_block .input-group .form-control {
  background-color: transparent;
  border-bottom: 1px solid #ddd;
  padding: 10px 20px 16px 0;
  height: auto;
  font-size: 16px;
  border-radius: 0;
  color: #555;
  height: 40px;
  text-align: left;
  font-style: normal;
}
body .login_block .back_block .login_title_block .form_block .input-group input[type=text]:focus,
body .login_block .back_block .login_title_block .form_block .input-group .form-control:focus {
  color: #0071C2;
  border-bottom: 1px solid #0071C2;
}
body .login_block .back_block .login_title_block .form_block .login_button_block {
  display: flex;
  justify-content: space-between;
}
body .login_block .back_block .login_title_block .form_block .login_button_block .btn-primary {
  padding: 9px 50px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Helvetica Now Display Medium";
  background-color: #0071C2 !important;
  display: inline-block;
}
body .login_block .back_block .login_title_block .form_block .login_button_block .btn-primary img {
  margin-left: 50px;
}
body .login_block .back_block .login_title_block .form_block .login_button_block p {
  font-size: 16px;
  font-weight: 500;
  font-family: "Helvetica Now Display Medium";
  margin: 0;
  color: #555;
  display: inline-block;
  margin: auto;
}
body .login_block .back_block .login_title_block .form_block .login_button_block a {
  width: 44px;
  height: 44px;
  display: block;
  border: 1.5px solid #0071C2;
  border-radius: 50%;
  margin-left: 15px;
  position: relative;
}
body .login_block .back_block .login_title_block .form_block .login_button_block a img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
body .login_block .login_background {
  display: block;
  position: relative;
}
body .login_block .login_background img {
  width: 100%;
}
body .login_block .login_background .sign_in_image {
  position: absolute;
  width: 120px;
  height: 120px;
  display: block;
  background-color: #fff;
  border-radius: 50%;
  top: 120px;
  right: 300px;
}
body .login_block .login_background .sign_in_image img {
  max-width: 100%;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
body .login_block .login_background .sign_in_image.two {
  width: 100px;
  height: 100px;
  top: 320px;
  right: 430px;
}
body .login_block .login_background .sign_in_image.three {
  width: 90px;
  height: 90px;
  top: 287px;
  right: 146px;
}
body .login_block .login_background .sign_in_image.four {
  width: 72px;
  height: 72px;
  top: 450px;
  right: 266px;
}
body .login_block .login_background .your_data_block {
  display: flex;
  justify-content: start;
  position: absolute;
  top: 590px;
  right: 130px;
  background-color: #fff;
  padding: 30px;
  border-radius: 22px;
}
body .login_block .login_background .your_data_block .data_line_block {
  margin-right: 50px;
}
body .login_block .login_background .your_data_block .data_line_block .line {
  width: 96px;
  height: 8px;
  border-radius: 10px;
  background-color: #D5D6DE;
  margin-bottom: 27px;
}
body .login_block .login_background .your_data_block .data_line_block .line:last-child {
  margin-bottom: 0;
}
body .login_block .login_background .your_data_block .data_line_block .line.white {
  padding: 9px;
  background-color: #fff;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
  width: 83px;
  height: 25px;
  border-radius: 30px;
}
body .login_block .login_background .your_data_block .data_line_block .line.blue {
  background-color: #0071C2;
  width: 65px;
  margin-bottom: 0;
}
body .login_block .login_background .your_data_block .data_line_block .line.grey {
  width: 62px;
  background-color: #E6E6EC;
}
body .login_block .login_background .your_data_block .data_line_block .line.lightgrey {
  width: 73px;
  background-color: #EDEDF1;
}
body .login_block .login_background .your_data_block .data_line_block .line.lightergrey {
  width: 84px;
  background-color: #EFEFEF;
}
body .login_block .login_background .your_data_block .your_data {
  margin-right: 30px;
}
body .login_block .login_background .your_data_block .your_data img {
  width: auto;
  margin-bottom: 25px;
}
body .login_block .login_background .your_data_block .your_data h6 {
  font-size: 18px;
  font-weight: 700;
  color: #0071C2;
  margin: 0;
  margin-bottom: 10px;
}
body .login_block .login_background .your_data_block .your_data p {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #555;
  margin: 0;
  max-width: 170px;
}
body .props_detail {
  display: block;
}
body .props_detail .property_list_image img {
  max-width: 100%;
}
body .props_detail .property_list_image .carousel {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
body .props_detail .property_list_image .carousel-image {
  width: 100%;
  height: 240px;
}
body .props_detail .property_list_image .carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.5);
  border: none;
  color: #333;
  font-size: 2em;
  padding: 0.5em 1em;
  cursor: pointer;
}
body .props_detail .property_list_image .carousel-button:focus {
  outline: none;
}
body .props_detail .property_list_image .carousel-button:first-child {
  left: 0;
}
body .props_detail .property_list_image .carousel-button:last-child {
  right: 0;
}
body .props_detail .property_list_detail {
  display: block;
}
body .props_detail .property_list_detail h2 {
  font-size: 16px;
  font-weight: 700;
  color: #0071C2;
  margin: 0;
  margin-bottom: 10px;
  white-space: break-spaces;
  word-break: break-word;
}
body .props_detail .property_list_detail .amenities_details {
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}
body .props_detail .property_list_detail .amenities_details p.status {
  padding: 5px 8px;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-size: 10px;
  font-weight: 400;
  margin: 0;
  margin-right: 10px;
}
body .props_detail .property_list_detail .amenities_details p.status.green {
  background-color: #5EAC18;
}
body .props_detail .property_list_detail .amenities_details .amenities_block {
  margin: 0 10px;
  display: inline-block;
}
body .props_detail .property_list_detail .amenities_details .amenities_block img {
  margin-right: 5px;
}
body .props_detail .property_list_detail .amenities_details .amenities_block span {
  font-size: 10px;
  font-weight: 400;
  color: #555;
}
body .props_detail .property_list_detail .quality_score_block {
  display: block;
}
body .props_detail .property_list_detail .quality_score_block .services_detail {
  margin-bottom: 15px;
  display: block;
}
body .props_detail .property_list_detail .quality_score_block .services_detail p {
  font-size: 10px;
  font-weight: 400;
  color: #555;
  margin: 0;
  padding: 0 20px;
  border-right: 1px solid #ddd;
  display: inline-block;
}
body .props_detail .property_list_detail .quality_score_block .services_detail p:first-child {
  padding-left: 0;
}
body .props_detail .property_list_detail .quality_score_block .services_detail p:last-child {
  padding-right: 0;
  border-right: none;
}
body .props_detail .property_list_detail .quality_score_block .services_detail p span {
  opacity: 0.7;
}
body .props_detail .property_list_detail .quality_score_block .amenities_detail_block {
  margin-bottom: 15px;
}
body .props_detail .property_list_detail .quality_score_block .amenities_detail_block p.status {
  padding: 5px 8px;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-size: 10px;
  font-weight: 400;
  margin: 0;
  margin-right: 10px;
  margin-bottom: 5px;
}
body .props_detail .property_list_detail .quality_score_block .amenities_detail_block p.status:last-child {
  margin-right: 0x;
}
body .props_detail .property_list_detail .quality_score_block .amenities_detail_block p.status.green {
  background-color: #5EAC18;
}
body .props_detail .property_list_detail .quality_score_block .amenities_detail_block p.status.blue {
  background-color: #0071C2;
}
body .props_detail .property_list_detail .quality_score_block .quality_block {
  display: block;
}
body .props_detail .property_list_detail .quality_score_block .quality_block h3 {
  font-size: 12px;
  font-weight: 700;
  color: #0071C2;
  margin: 0;
  margin-bottom: 5px;
}
body .props_detail .property_list_detail .quality_score_block .quality_block p {
  font-size: 10px;
  font-weight: 400;
  color: #555;
  margin: 0;
  margin-bottom: 10px;
}
body .props_detail .property_list_detail .quality_score_block .quality_block .services_detail {
  margin-bottom: 0;
}
body .modal_block {
  max-width: 650px;
  float: right;
  margin: 10px 25px;
  border: none;
  outline: none;
}
body .modal_block.upgrade {
  max-width: 1000px;
}
body .modal_block.add_requirements {
  max-width: 100%;
}
body .modal_block.add_requirements .type_categories_block {
  margin: 0;
}
body .modal_block.add_requirements .type_categories_block .full_width_dropdown {
  margin-bottom: 40px;
  margin-left: 20px;
  margin-right: 20px;
}
body .modal_block.add_requirements .type_categories_block .full_width_dropdown .form-group {
  margin: 0;
  margin-bottom: 20px;
  width: 100%;
}
body .modal_block.add_requirements .type_categories_block .full_width_dropdown a {
  background-color: #0071C2;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  font-family: "Helvetica Now Display Medium";
  border-radius: 30px;
  padding: 15px 18px;
  display: inline-block;
  text-transform: uppercase;
}
body .modal_block.add_requirements .type_categories_block .form-group {
  margin-bottom: 20px;
  position: relative;
}
body .modal_block.add_requirements .type_categories_block .form-group .form-control {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 16px 20px;
  height: auto;
  font-size: 14px;
  font-weight: 400;
  border-radius: 7px;
  color: #555;
  height: 50px;
}
body .modal_block.add_requirements .type_categories_block .form-group .form-control.auto_complete {
  padding: 0;
}
body .modal_block.add_requirements .type_categories_block .form-group .form-control.auto_complete input {
  font-size: 14px;
  margin-bottom: 0;
  color: #555;
  font-weight: 400;
  margin-top: -3px;
  padding: 9px 11px;
}
body .modal_block.add_requirements .type_categories_block .form-group .form-control.auto_complete input input::-moz-placeholder {
  color: #555;
}
body .modal_block.add_requirements .type_categories_block .form-group .form-control.auto_complete input input::placeholder {
  color: #555;
}
body .modal_block.add_requirements .type_categories_block .form-group .form-control.auto_complete input input::-webkite-inline-placeholder {
  color: #555;
}
body .modal_block.add_requirements .type_categories_block .form-group .form-control.auto_complete .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
}
body .modal_block.add_requirements .type_categories_block .form-group .form-control.auto_complete div.MuiAutocomplete-root.MuiPopper-root.MuiAutocomplete-popperDisable.portal.css-1rndd9j-MuiPopper-root-MuiAutocomplete-popper {
  font-size: 14px;
  margin-bottom: 0;
  color: #555;
  font-weight: 400;
}
body .modal_block.add_requirements .type_categories_block .form-group .form-control.auto_complete .css-1q60rmi-MuiAutocomplete-endAdornment {
  top: calc(50% - 12px);
  right: 20px;
  color: #555;
}
body .modal_block.add_requirements .type_categories_block .form-group .form-control .MuiSelect-select {
  padding: 0;
  font-style: normal;
}
body .modal_block.add_requirements .type_categories_block .form-group .form-control fieldset {
  border-color: transparent;
}
body .modal_block.add_requirements .type_categories_block .form-group .form-control .select_text {
  font-style: normal;
}
body .modal_block.add_requirements .type_categories_block .form-group .form-control svg {
  right: 20px;
  color: #555;
}
body .modal_block.add_requirements .type_categories_block h5 {
  font-size: 20px;
  font-weight: 700;
  color: #0071C2;
  margin: 0 20px;
  margin-bottom: 20px;
  font-family: "Helvetica Now Display Bold";
}
body .modal_block.add_requirements .type_categories_block .assessment_range_block {
  margin-bottom: 40px;
  margin-left: 20px;
  margin-right: 20px;
}
body .modal_block.add_requirements .type_categories_block .assessment_range_block .budget_range {
  padding: 9px 0;
}
body .modal_block.add_requirements .type_categories_block .type_block.property_type {
  margin: 40px 0;
}
body .modal_block.add_requirements .type_categories_block .type_block.property_type h5 {
  margin-left: 0;
  margin-right: 0;
}
body .modal_block.add_requirements .type_categories_block .type_block.property_type img {
  max-width: 60px;
}
body .modal_block .modal_header_block {
  background-color: #0071C2;
  border-radius: 10px 10px 0 0;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
}
body .modal_block .modal_header_block h2 {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin: auto 0;
}
body .modal_block .modal_header_block button {
  background-color: transparent;
  border: none;
}
body .modal_block .modal_footer_block {
  display: block;
  padding: 20px 30px 20px 30px;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
}
body .modal_block .modal_footer_block .button_block {
  text-align: right;
  padding-bottom: 0;
}
body .modal_block .modal_footer_block .button_block .links {
  margin-left: 15px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  padding: 14px 18px;
  display: inline-block;
  border: 1px solid #0071C2;
  background-color: #fff;
  border-radius: 50px;
  text-transform: uppercase;
}
body .modal_block .modal_footer_block .button_block .links:hover {
  background-color: #0071C2;
  color: #fff;
}
body .modal_block .modal_body_block {
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 0;
  max-height: 350px;
  overflow-y: auto;
}
body .modal_block .modal_body_block.requirements_body_block {
  max-width: 1100px;
}
body .modal_block .modal_body_block .publish_properties_block p {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}
body .modal_block .modal_body_block .publish_properties_block p span.red_text {
  color: #EC1A25;
}
body .modal_block .modal_body_block .publish_properties_block h6 {
  font-size: 16px;
  font-weight: 500;
  font-family: "Atak Medium";
  margin: 0;
  color: #0071C2;
}
body .modal_block .modal_body_block .credit_numbers {
  display: flex;
  justify-content: space-between;
}
body .modal_block .modal_body_block .credit_numbers .avail_credits {
  margin-left: 70px;
}
body .modal_block .modal_body_block .credit_numbers .avail_credits p.blue_text {
  color: #0071C2;
}
body .modal_block .modal_body_block .credit_numbers .avail_credits p.blue_text img {
  margin-right: 5px;
}
body .modal_block .modal_body_block .upgrade_plan_block {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
body .modal_block .modal_body_block .upgrade_plan_block .upgrade_plan_detail {
  padding: 20px;
  width: 50%;
  border: 1px solid #ddd;
  display: block;
  border-radius: 10px;
  transition: all 0.35s ease-in-out;
}
body .modal_block .modal_body_block .upgrade_plan_block .upgrade_plan_detail.active {
  border: 1px solid #0071C2;
}
body .modal_block .modal_body_block .upgrade_plan_block .upgrade_plan_detail p {
  font-size: 14px;
  margin: 0;
}
body .modal_block .modal_body_block .upgrade_plan_block .upgrade_plan_detail h5 {
  font-size: 20px;
  font-weight: 700;
  color: #0071C2;
  margin: 0;
  margin-bottom: 20px;
}
body .modal_block .modal_body_block .upgrade_plan_block .upgrade_plan_detail .duration_block {
  padding: 0;
}
body .modal_block .modal_body_block .upgrade_plan_block .upgrade_plan_detail .duration_block p {
  font-size: 14px;
  font-weight: 400;
  color: #555;
  margin: 0;
}
body .modal_block .modal_body_block .upgrade_plan_block .upgrade_plan_detail .duration_block .form_block .radio_button_block .css-1hbvpl3-MuiSvgIcon-root {
  width: 18px;
  height: 18px;
}
body .modal_block .modal_body_block .upgrade_plan_block .upgrade_plan_detail .duration_block .form_block .radio_button_block .css-11zohuh-MuiSvgIcon-root {
  font-size: 18px;
}
body .modal_block .modal_body_block .upgrade_plan_block .upgrade_plan_detail .duration_block .form_block .radio_button_block label span {
  font-size: 14px !important;
  font-family: "Atak Regular";
}
body .modal_block .modal_body_block .upgrade_plan_block .upgrade_plan_detail .checkbox_block {
  padding-bottom: 10px;
}
body .modal_block .modal_body_block .upgrade_plan_block .upgrade_plan_detail .checkbox_block .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  padding-left: 0;
}
body .modal_block .modal_body_block .upgrade_plan_block .upgrade_plan_detail .checkbox_block .css-i4bv87-MuiSvgIcon-root {
  width: 22px;
  height: 22px;
  margin-left: -2px;
}
body .modal_block .modal_body_block .upgrade_plan_block .upgrade_plan_detail .checkbox_block .css-ahj2mt-MuiTypography-root {
  font-size: 14px;
  font-family: "Atak Regular";
}
body .modal_block .modal_body_block .upgrade_plan_block .upgrade_plan_detail .upgrade_credit_block {
  padding-bottom: 15px;
}
body .modal_block .modal_body_block .upgrade_plan_block .upgrade_plan_detail .upgrade_credit_block h6 {
  font-size: 16px;
  font-weight: 700;
  color: #0071C2;
  margin: 0;
  margin-bottom: 10px;
}
body .modal_block .modal_body_block .upgrade_plan_block .upgrade_plan_detail .upgrade_credit_block p {
  font-size: 16px;
  font-weight: 400;
  color: #555;
  margin: 0;
  display: inline-block;
}
body .modal_block .modal_body_block .upgrade_plan_block .upgrade_plan_detail .upgrade_credit_block span {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  background-color: #0071C2;
  padding: 3px 6px;
  border-radius: 5px;
  display: inline-block;
  margin-left: 5px;
}
body .modal_block .modal_body_block .type_block {
  padding: 0 20px;
}
body .modal_block .modal_body_block .type_block.border_right {
  border-right: 1px solid #ddd;
}
body .modal_block .modal_body_block .type_block.margint {
  margin-bottom: 30px;
}
body .modal_block .modal_body_block .type_block .types {
  text-align: center;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  display: block;
}
body .modal_block .modal_body_block .type_block .types h6 {
  font-size: 16px;
  color: #0071C2;
  margin: 0;
  margin-top: 10px;
}
body .modal_block .modal_body_block .type_block .types.active {
  border: 1px solid #0071C2;
}
body .modal_block .modal_body_block .type_block .types.deactive {
  border: 1px solid #fff;
}
body .modal_block .modal_body_block .amenities_list {
  margin-bottom: 0;
}
body .modal_block .modal_body_block .amenities_list.requirements {
  margin: 0 20px 40px 20px;
}
body .modal_block .modal_body_block .form_block.requirement {
  margin: 0 10px;
}
body .modal_block .modal_body_block .form-group {
  margin-bottom: 15px;
}
body .modal_block .modal_body_block .form-group .form-control {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 14px 20px;
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 7px;
  color: #555;
}
body .modal_block .modal_body_block .form-group .form-control.border_temp {
  padding: 0;
}
body .modal_block .modal_body_block .form-group .form-control.border_temp .MuiSelect-select {
  padding: 16px 20px;
  font-style: normal;
}
body .modal_block .modal_body_block .form-group .form-control.border_temp fieldset {
  border-color: transparent;
}
body .modal_block .modal_body_block .form-group .form-control.border_temp .select_text {
  font-style: normal;
}
body .modal_block .modal_body_block .form-group .form-control.border_temp svg {
  right: 20px;
  color: #555;
}
body .modal_block .modal_body_block .form-group .form-control.right_space {
  padding-right: 70px;
}
body .modal_block .modal_body_block .form-group .form-control::-moz-placeholder {
  color: #555;
}
body .modal_block .modal_body_block .form-group .form-control::placeholder {
  color: #555;
}
body .modal_block .modal_body_block .form-group .form-line {
  border: none;
  position: relative;
}
body .modal_block .modal_body_block .form-group .form-line::after {
  border: none;
}
body .modal_block .modal_body_block .form-group .form-line .form_control_title {
  position: absolute;
  right: 20px;
  top: 17px;
  font-size: 12px;
  color: #555;
  opacity: 0.7;
  background-color: #fff;
}
body .modal_block .modal_body_block .form-group .btn-default {
  padding: 0;
  font-size: 14px;
  color: #555;
  border: none;
  background-color: transparent !important;
}
body .modal_block .modal_body_block .form-group .bootstrap-select {
  border: 1px solid #ddd !important;
}
body .modal_block .modal_body_block .form-group .bootstrap-select.btn-group .dropdown-toggle .filter-option {
  top: 0 !important;
  margin-left: 0;
}
body .modal_block .modal_body_block .form-group .bootstrap-select.btn-group .dropdown-toggle .caret {
  margin-top: 2px;
}
body .modal_block .modal_body_block p {
  font-size: 16px;
  font-weight: 400;
  color: #555;
  line-height: 24px;
  margin: 0;
  margin-bottom: 20px;
}
body .modal_block .modal_body_block .form_block .row {
  margin-left: -5px;
  margin-right: -5px;
}
body .modal_block .modal_body_block .form_block .row .col-md-3,
body .modal_block .modal_body_block .form_block .row .col-md-6,
body .modal_block .modal_body_block .form_block .row .col-md-12 {
  padding-left: 5px;
  padding-right: 5px;
}
body .modal_block .modal_body_block .form_block .form-group {
  margin-bottom: 15px;
}
body .modal_block .modal_body_block .form_block .form-group .form-control {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 14px 20px;
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 7px;
  color: #555;
}
body .modal_block .modal_body_block .form_block .form-group .form-control.border_temp {
  padding: 0;
}
body .modal_block .modal_body_block .form_block .form-group .form-control.border_temp .MuiSelect-select {
  padding: 16px 20px;
  font-style: normal;
}
body .modal_block .modal_body_block .form_block .form-group .form-control.border_temp fieldset {
  border-color: transparent;
}
body .modal_block .modal_body_block .form_block .form-group .form-control.border_temp .select_text {
  font-style: normal;
}
body .modal_block .modal_body_block .form_block .form-group .form-control.border_temp svg {
  right: 20px;
  color: #555;
}
body .modal_block .modal_body_block .form_block .form-group .form-control.right_space {
  padding-right: 70px;
}
body .modal_block .modal_body_block .form_block .form-group .form-control::-moz-placeholder {
  color: #555;
}
body .modal_block .modal_body_block .form_block .form-group .form-control::placeholder {
  color: #555;
}
body .modal_block .modal_body_block .form_block .form-group .form-line {
  border: none;
  position: relative;
}
body .modal_block .modal_body_block .form_block .form-group .form-line::after {
  border: none;
}
body .modal_block .modal_body_block .form_block .form-group .form-line .form_control_title {
  position: absolute;
  right: 20px;
  top: 17px;
  font-size: 12px;
  color: #555;
  opacity: 0.7;
  background-color: #fff;
}
body .modal_block .modal_body_block .form_block .form-group .btn-default {
  padding: 0;
  font-size: 14px;
  color: #555;
  border: none;
  background-color: transparent !important;
}
body .modal_block .modal_body_block .form_block .form-group .bootstrap-select {
  border: 1px solid #ddd !important;
}
body .modal_block .modal_body_block .form_block .form-group .bootstrap-select.btn-group .dropdown-toggle .filter-option {
  top: 0 !important;
  margin-left: 0;
}
body .modal_block .modal_body_block .form_block .form-group .bootstrap-select.btn-group .dropdown-toggle .caret {
  margin-top: 2px;
}
body .modal_block .modal_body_block h4 {
  font-size: 16px;
  font-weight: 700;
  color: #0071C2;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 20px;
}
body .modal_block .modal_body_block .plans_detail {
  background-color: #f5f5f5;
  padding: 15px;
}
body .modal_block .modal_body_block .plans_detail .plans_block h6 {
  font-size: 14px;
  font-weight: 400;
  color: #555;
  padding-bottom: 10px;
  margin: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  font-family: "Atak Regular";
  line-height: normal;
}
body .modal_block .modal_body_block .plans_detail .plans_block p {
  font-size: 12px;
  font-weight: 400;
  color: #555;
  margin: 0;
  opacity: 0.7;
  margin-bottom: 7px;
  line-height: normal;
}
body .modal_block .modal_body_block .plans_detail .plans_block p:last-child {
  margin-bottom: 0;
}
body .navbar.common {
  background-color: #fff;
  min-height: 85px;
}
body .navbar.common .navbar-nav > li > a {
  background-color: transparent !important;
}
body .navbar.common .label-count {
  color: #fff;
  background-color: #555;
}
body .navbar.common .navbar-brand {
  min-height: 65px;
  padding: 0;
}
body .navbar.common .navbar-right {
  margin-top: 10px !important;
}
body .navbar.common .user-info .info-container {
  cursor: default;
  display: block;
}
body .navbar.common .user-info .info-container .user-helper-dropdown {
  position: absolute;
  right: -3px;
  top: 25px;
  -ms-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
}
body .navbar.common .user-info .info-container .user-helper-dropdown .dropdown-menu {
  margin-top: 10px !important;
  padding: 10px;
  border-radius: 10px;
}
body .navbar.common .user-info .info-container .user-helper-dropdown .dropdown-menu .name.custom_name,
body .navbar.common .user-info .info-container .user-helper-dropdown .dropdown-menu .email.custom_email {
  padding: 5px 15px;
}
body .navbar.common .user-info .info-container .user-helper-dropdown .dropdown-menu ul.menu {
  list-style: none;
}
body .navbar.common .user-info .info-container .user-helper-dropdown .dropdown-menu > li > a:hover, body .navbar.common .user-info .info-container .user-helper-dropdown .dropdown-menu > li > a:focus {
  color: #0071C2;
}
body .navbar.common .user-info .info-container .user-helper-dropdown .dropdown-menu > li > a {
  color: #555;
}
body .navbar.common .user-info .user_image {
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 15px;
  margin-top: 7px;
}
body .tab_block {
  padding: 30px;
  background-color: #fff;
  border-radius: 20px;
}
body .tab_block .tab-nav-right {
  border-bottom: 1px solid #ddd;
  color: #555;
}
body .tab_block .tab-nav-right .tabs_title {
  font-size: 14px;
  font-weight: 400;
  color: #555;
  text-transform: capitalize;
  opacity: 0.7;
  padding: 10px 20px 15px 20px;
  font-family: "Helvetica Now Display Regular";
  letter-spacing: 0.2px;
}
body .tab_block .tab-nav-right .tabs_title.Mui-selected {
  opacity: 1;
}
body .tab_content_block {
  padding: 30px 0 0 0;
}
body .tab_content_block.setting {
  padding: 0;
}
body .tab_content_block .row {
  margin-left: -5px;
  margin-right: -5px;
}
body .tab_content_block .row .col-sm-9,
body .tab_content_block .row .col-sm-3,
body .tab_content_block .row .col-md-4,
body .tab_content_block .row .col-md-8 {
  padding-left: 5px;
  padding-right: 5px;
}
body .tab_content_block .rightside_block {
  background-color: #fff;
  border-radius: 20px;
  padding: 30px;
}
body .tab_content_block .rightside_block h4 {
  font-size: 16px;
  color: #0071C2;
  margin: 0;
  margin-bottom: 30px;
}
body .tab_content_block .rightside_block .update_block {
  margin-bottom: 30px;
}
body .tab_content_block .rightside_block .update_block p {
  font-size: 14px;
  color: #555;
  margin: 0;
  margin-bottom: 20px;
}
body .tab_content_block .rightside_block .update_block p:last-child {
  margin-bottom: 0;
}
body .tab_content_block .rightside_block .form-group {
  margin-bottom: 20px;
}
body .tab_content_block .rightside_block .form-group .form-control {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 16px 20px;
  height: auto;
  font-size: 14px;
  font-weight: 400;
  border-radius: 7px;
  color: #555;
  height: 50px;
  line-height: normal;
}
body .tab_content_block .rightside_block .form-group .form-control .MuiSelect-select {
  padding: 0;
  font-style: normal;
}
body .tab_content_block .rightside_block .form-group .form-control fieldset {
  border-color: transparent;
}
body .tab_content_block .rightside_block .form-group .form-control .select_text {
  font-style: normal;
}
body .tab_content_block .rightside_block .form-group .form-control svg {
  right: 20px;
  color: #555;
}
body .tab_content_block .rightside_block .form-group .form-control.right_space {
  padding-right: 70px;
}
body .tab_content_block .rightside_block .form-group .form-control::-moz-placeholder {
  color: #555;
}
body .tab_content_block .rightside_block .form-group .form-control::placeholder {
  color: #555;
}
body .tab_content_block .rightside_block .form-group .form-line {
  border: none;
  position: relative;
}
body .tab_content_block .rightside_block .form-group .form-line::after {
  border: none;
}
body .tab_content_block .rightside_block .form-group .form-line .form_control_title {
  position: absolute;
  right: 20px;
  top: 17px;
  font-size: 12px;
  color: #555;
  opacity: 0.7;
  background-color: #fff;
}
body .tab_content_block .rightside_block .form-group .form-line .custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 50px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400 !important;
}
body .tab_content_block .rightside_block .form-group .form-line .custom-file .custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 35px;
  margin: 0;
  opacity: 0;
}
body .tab_content_block .rightside_block .form-group .form-line .custom-file .custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: auto;
  padding: 20px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 400 !important;
}
body .tab_content_block .rightside_block .form-group .form-line .custom-file .custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
body .tab_content_block .rightside_block .form-group .form-line .custom-file .custom-file-label::after {
  position: absolute;
  right: 20px;
  top: 14px;
  z-index: 3;
  display: block;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  content: "Browse";
  background-color: #0071C2;
  border-radius: 20px;
}
body .tab_content_block .rightside_block .form-group .btn-default {
  padding: 0;
  font-size: 14px;
  color: #555;
  border: none;
  background-color: transparent !important;
}
body .tab_content_block .rightside_block .form-group .bootstrap-select {
  border: 1px solid #ddd !important;
}
body .tab_content_block .rightside_block .form-group .bootstrap-select.btn-group .dropdown-toggle .filter-option {
  top: 0 !important;
  margin-left: 0;
}
body .tab_content_block .rightside_block .form-group .bootstrap-select.btn-group .dropdown-toggle .caret {
  margin-top: 2px;
}
body .tab_content_block .rightside_block .availability_block {
  margin-bottom: 5px;
}
body .tab_content_block .rightside_block .availability_block h6 {
  font-size: 16px;
  font-weight: 400;
  color: #555;
  font-family: "Atak Regular";
  margin: 0;
  margin-bottom: 5px;
}
body .tab_content_block .rightside_block .availability_block [type=checkbox].filled-in:checked.chk-col-pink + label:after {
  background-color: #5EAC18;
  border: 2px solid #5EAC18;
}
body .tab_content_block .rightside_block .availability_block [type=checkbox] + label {
  font-size: 14px;
  font-weight: 400;
  color: #555;
}
body .tab_content_block .rightside_block .listing_status_block {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 7px;
  margin-bottom: 30px;
}
body .tab_content_block .rightside_block .listing_status_block h5 {
  font-size: 16px;
  font-weight: 500;
  font-family: "Atak Medium";
  color: #555;
  margin: 0;
  margin-bottom: 15px;
}
body .tab_content_block .rightside_block .listing_status_block .listing_block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
body .tab_content_block .rightside_block .listing_status_block .listing_block p {
  font-size: 14px;
  color: #555;
  margin: auto 0;
}
body .tab_content_block .rightside_block .listing_status_block .listing_block a {
  background-color: #0071C2;
  color: #fff;
  padding: 4px 8px;
  border-radius: 3px;
}
body .tab_content_block .rightside_block .listing_status_block .listing_block a.green {
  background-color: #5EAC18;
}
body .tab_content_block .rightside_block .listing_status_block .listing_block a.blue {
  background-color: #0071C2;
}
body .tab_content_block .rightside_block .listing_status_block span {
  font-size: 10px;
  font-style: italic;
}
body .tab_content_block .rightside_block .quality_score_block {
  margin-bottom: 30px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 7px;
}
body .tab_content_block .rightside_block .quality_score_block span {
  font-size: 10px;
  font-weight: 400;
  color: #555;
  opacity: 0.7;
  margin-bottom: 15px;
  display: block;
}
body .tab_content_block .rightside_block .quality_score_block .quality_score_title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
body .tab_content_block .rightside_block .quality_score_block .quality_score_title h5 {
  font-size: 16px;
  font-weight: 500;
  font-family: "Atak Medium";
  color: #555;
  margin: 0;
  margin: auto 0;
}
body .tab_content_block .rightside_block .quality_score_block .quality_score_title .quality_block {
  display: flex;
  justify-content: center;
}
body .tab_content_block .rightside_block .quality_score_block .quality_score_title .quality_block .progress_bar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid #5EAC18;
  margin-right: 5px;
  display: inline-blockss;
}
body .tab_content_block .rightside_block .quality_score_block .quality_score_title .quality_block .opac {
  font-size: 10px;
  font-weight: 400;
  color: #555;
  opacity: 0.7;
  margin-top: 8px;
}
body .tab_content_block .rightside_block .quality_score_block .quality_detail .quality_tag {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
body .tab_content_block .rightside_block .quality_score_block .quality_detail .quality_tag:last-child {
  margin-bottom: 0;
}
body .tab_content_block .rightside_block .quality_score_block .quality_detail .quality_tag h6 {
  font-size: 12px;
  font-weight: 400;
  color: #555;
  margin: 0;
  font-family: "Atak Regular";
}
body .tab_content_block .rightside_block .quality_score_block .quality_detail .quality_tag p {
  font-size: 12px;
  font-weight: 400;
  color: #555;
  margin: 0;
  opacity: 0.7;
}
body .tab_content_block .file-drop-area {
  position: relative;
  padding: 25px;
  border: 2px dashed #0071C2;
  border-radius: 20px;
  transition: 0.2s;
  text-align: center;
}
body .tab_content_block .file-drop-area.images {
  border: none;
  background-color: #f5f5f5;
  border-radius: 0;
  padding: 0;
  height: 100%;
  min-height: 187px;
}
body .tab_content_block .file-drop-area.images figure {
  text-align: center;
  display: block;
}
body .tab_content_block .file-drop-area.images figure img {
  margin-bottom: 0;
  width: auto;
}
body .tab_content_block .file-drop-area.images .choose-file-button {
  padding: 0;
  background-color: transparent;
  color: #555;
  font-size: 16px;
  font-weight: 400;
  opacity: 0.7;
  text-transform: capitalize;
}
body .tab_content_block .file-drop-area img {
  margin-bottom: 20px;
}
body .tab_content_block .file-drop-area .show-message {
  display: block;
  font-size: 20px;
  font-weight: 700;
  color: #0071C2;
  margin-bottom: 20px;
  font-family: "Helvetica Now Display Bold";
}
body .tab_content_block .file-drop-area .choose-file-button {
  flex-shrink: 0;
  background-color: #0071C2;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  font-family: "Helvetica Now Display Medium";
  border-radius: 30px;
  padding: 15px 18px;
  display: inline-block;
  text-transform: uppercase;
}
body .tab_content_block .file-drop-area .file-message {
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  margin-bottom: 20px;
}
body .tab_content_block .file-drop-area .file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}
body .tab_content_block .panel-group {
  margin-bottom: 30px;
  padding: 30px;
  background-color: #fff;
  border-radius: 20px;
}
body .tab_content_block .panel-group .panel {
  box-shadow: none;
  border: none;
}
body .tab_content_block .panel-group .panel .button_block {
  padding-top: 20px;
  text-align: right;
}
body .tab_content_block .panel-group .panel .button_block .links {
  margin-left: 15px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  padding: 14px 18px;
  display: inline-block;
  border: 1px solid #0071C2;
  background-color: #fff;
  border-radius: 50px;
  text-transform: uppercase;
}
body .tab_content_block .panel-group .panel .button_block .links:hover {
  background-color: #0071C2;
  color: #fff;
}
body .tab_content_block .panel-group .panel .panel-heading a {
  padding: 0;
  display: flex;
  justify-content: space-between;
}
body .tab_content_block .panel-group .panel .panel-heading a .credit_balance_block {
  text-align: right;
  margin-bottom: 15px;
}
body .tab_content_block .panel-group .panel .panel-heading a .credit_balance_block .links {
  margin-left: 15px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  padding: 14px 18px;
  display: inline-block;
  border: 1px solid #0071C2;
  background-color: #fff;
  border-radius: 50px;
  text-transform: uppercase;
}
body .tab_content_block .panel-group .panel .panel-heading a .credit_balance_block .links.export {
  padding: 13px 18px;
}
body .tab_content_block .panel-group .panel .panel-heading a .credit_balance_block .links:hover {
  background-color: #0071C2;
  color: #fff;
}
body .tab_content_block .panel-group .panel .panel-heading a .credit_balance_block .balance_block {
  border: 1px solid #ddd;
  border-radius: 5px;
  display: inline-block;
}
body .tab_content_block .panel-group .panel .panel-heading a .credit_balance_block .balance_block p {
  font-size: 14px;
  font-weight: 400;
  color: #555;
  margin: 0;
  display: inline-block;
  margin: 10px 0 10px 20px;
  padding-right: 20px;
}
body .tab_content_block .panel-group .panel .panel-heading a .credit_balance_block .balance_block p span {
  font-size: 14px;
  font-weight: 400;
  color: #555;
  margin: 0;
}
body .tab_content_block .panel-group .panel .panel-heading a .credit_balance_block .balance_block p img {
  margin-left: 10px;
}
body .tab_content_block .panel-group .panel .panel-heading a .credit_balance_block .balance_block button {
  padding: 9px 15px;
  border: none;
  border-left: 1px solid #ddd;
  border-radius: 0 6px 6px 0;
}
body .tab_content_block .panel-group .panel .panel-heading a span {
  font-size: 25px;
  font-weight: 700;
  color: #0071C2;
  font-family: "Helvetica Now Display Bold";
}
body .tab_content_block .panel-group .panel .panel-heading a i {
  width: 28px;
  height: 28px;
  display: inline-block;
  background-color: #0071C2;
  color: #fff;
  font-style: normal;
  font-size: 16px;
  font-family: uicons-bold-rounded !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 50%;
  position: relative;
}
body .tab_content_block .panel-group .panel .panel-heading a i.openIcon:after {
  content: "\f737";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
body .tab_content_block .panel-group .panel .panel-heading a i.closeIcon:after {
  content: "\f813";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
body .tab_content_block .panel-group .panel-body {
  border-top: none !important;
  padding: 0;
  padding-top: 30px;
}
body .tab_content_block .panel-group .panel-body .card {
  box-shadow: none;
  margin-bottom: 0;
}
body .tab_content_block .panel-group .panel-body .card .shortlist_dropdown {
  display: flex;
  justify-content: space-between;
}
body .tab_content_block .panel-group .panel-body .card .rating_star .style-module_fillIcons__6---A {
  color: #5EAC18 !important;
}
body .tab_content_block .panel-group .panel-body .card .form-group.checkbox span svg {
  height: 25px;
  width: 25px;
}
body .tab_content_block .panel-group .panel-body .card .props_title {
  font-size: 16px;
  font-weight: 400;
  color: #555;
  margin: 0;
  margin-bottom: 30px;
}
body .tab_content_block .panel-group .panel-body .card .props_detail {
  border: 1px solid #0071C2;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  margin-bottom: 20px;
}
body .tab_content_block .panel-group .panel-body .card .props_detail .like_property {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  display: inline-block;
  background: #fff;
  color: #0071C2;
  border-radius: 5px;
  text-align: center;
}
body .tab_content_block .panel-group .panel-body .card .props_detail .like_property .like_icon {
  padding: 5px;
}
body .tab_content_block .panel-group .panel-body .card .props_detail .property_list_detail {
  margin-left: 15px;
}
body .tab_content_block .panel-group .panel-body .card .props_detail .property_list_detail h2 {
  margin-bottom: 15px;
}
body .tab_content_block .panel-group .panel-body .card .props_detail .property_list_detail p.addressing {
  font-size: 14px;
  font-weight: 400;
  color: #555;
  margin: 0;
  margin-bottom: 15px;
}
body .tab_content_block .panel-group .panel-body .card .props_detail .property_list_detail p.addressing img {
  margin-right: 10px;
}
body .tab_content_block .panel-group .panel-body .card .props_detail .property_list_detail .amenities_details {
  margin-bottom: 15px;
  padding: 0;
  border: none;
}
body .tab_content_block .panel-group .panel-body .card .props_detail .property_list_detail .amenities_details p.status {
  margin: 0;
}
body .tab_content_block .panel-group .panel-body .card .props_detail .property_list_detail .amenities_details .amenities_detail_block {
  padding: 0 30px;
  border-right: 1px solid #ddd;
  display: inline-block;
}
body .tab_content_block .panel-group .panel-body .card .props_detail .property_list_detail .amenities_details .amenities_detail_block.last {
  padding: 0;
  padding-left: 20px;
}
body .tab_content_block .panel-group .panel-body .card .props_detail .property_list_detail .amenities_details .amenities_detail_block:first-child {
  padding-left: 0;
}
body .tab_content_block .panel-group .panel-body .card .props_detail .property_list_detail .amenities_details .amenities_detail_block:last-child {
  border-right: none;
}
body .tab_content_block .panel-group .panel-body .card .props_detail .property_list_detail .amenities_details .amenities_detail_block img {
  margin-right: 6px;
}
body .tab_content_block .panel-group .panel-body .card .props_detail .property_list_detail .amenities_details .amenities_detail_block span {
  font-size: 14px;
  font-weight: 400;
  color: #555;
}
body .tab_content_block .panel-group .panel-body .card .props_detail:hover .props_detail_overlay {
  visibility: visible;
}
body .tab_content_block .panel-group .panel-body .card .props_detail .props_detail_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid transparent;
  display: block;
  padding: 30px;
  visibility: hidden;
  transition: all 0.35s ease-in-out;
  border-radius: 10px;
}
body .tab_content_block .panel-group .panel-body .card .props_detail .props_detail_overlay .form-group .form-control {
  width: auto;
}
body .tab_content_block .panel-group .panel-body .card .requirement_block {
  display: flex;
  justify-content: space-between;
}
body .tab_content_block .panel-group .panel-body .card .requirement_block p {
  font-size: 14px;
  font-weight: 400;
  color: #555;
  margin: 0;
  margin: auto 0;
}
body .tab_content_block .panel-group .panel-body .card .requirement_block p img {
  margin-right: 4px;
}
body .tab_content_block .panel-group .panel-body .card .requirement_block p:first-child {
  margin: auto 0;
}
body .tab_content_block .panel-group .panel-body .card .requirement_block a {
  font-size: 14px;
  font-weight: 400;
  color: #0071C2;
  border: 1px solid #0071C2;
  padding: 14px 18px;
  border-radius: 30px;
  display: inline-block;
  text-transform: uppercase;
}
body .tab_content_block .panel-group .panel-body .card .requirement_block a:hover {
  background-color: #0071C2;
  color: #fff;
}
body .tab_content_block .panel-group .panel-body .card .amenities_block p {
  font-size: 16px;
  margin: 0;
  margin-bottom: 30px;
}
body .tab_content_block .panel-group .panel-body .card .amenities_block a {
  border: 1px solid #0071C2;
  color: #0071C2;
  font-size: 14px;
  background-color: #fff;
  border-radius: 3px;
  padding: 6px 15px;
  display: inline-block;
}
body .tab_content_block .panel-group .panel-body .card .amenities_block span {
  border: 1px solid #0071C2;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  background-color: #0071C2;
  border-radius: 3px;
  padding: 6px 15px;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
}
body .tab_content_block .panel-group .panel-body .card .type_block {
  padding: 20px 50px;
}
body .tab_content_block .panel-group .panel-body .card .type_block.border_right {
  border-right: 1px solid #ddd;
}
body .tab_content_block .panel-group .panel-body .card .type_block.margint {
  margin-bottom: 30px;
}
body .tab_content_block .panel-group .panel-body .card .type_block .types {
  text-align: center;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  display: block;
}
body .tab_content_block .panel-group .panel-body .card .type_block .types h6 {
  font-size: 16px;
  color: #0071C2;
  margin: 0;
  margin-top: 10px;
}
body .tab_content_block .panel-group .panel-body .card .type_block .types.active {
  border: 1px solid #0071C2;
}
body .tab_content_block .panel-group .panel-body .card .type_block .types.deactive {
  border: 1px solid #fff;
}
body .tab_content_block .panel-group .panel-body .card .main_class {
  padding: 0;
}
body .tab_content_block .panel-group .panel-body .card .main_class .form-group {
  margin-bottom: 20px;
  position: relative;
}
body .tab_content_block .panel-group .panel-body .card .main_class .form-group .form-line {
  border: none;
  position: relative;
}
body .tab_content_block .panel-group .panel-body .card .main_class .form-group .form-line .custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 64px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400 !important;
}
body .tab_content_block .panel-group .panel-body .card .main_class .form-group .form-line .custom-file .custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 35px;
  margin: 0;
  opacity: 0;
}
body .tab_content_block .panel-group .panel-body .card .main_class .form-group .form-line .custom-file .custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: auto;
  padding: 20px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 400 !important;
}
body .tab_content_block .panel-group .panel-body .card .main_class .form-group .form-line .custom-file .custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
body .tab_content_block .panel-group .panel-body .card .main_class .form-group .form-line .custom-file .custom-file-label::after {
  position: absolute;
  right: 20px;
  top: 14px;
  z-index: 3;
  display: block;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  content: "Browse";
  background-color: #0071C2;
  border-radius: 20px;
}
body .tab_content_block .panel-group .panel-body .card p {
  margin: 30px 0 15px 0;
}
body .tab_content_block .panel-group .panel-body .card p:first-child {
  margin-top: 0;
}
body .tab_content_block .panel-group .panel-body .card p:last-child {
  margin-bottom: 0;
}
body .tab_content_block .panel-group .panel-body .card .form_block .row {
  margin-left: -5px;
  margin-right: -5px;
}
body .tab_content_block .panel-group .panel-body .card .form_block .row .col-sm-6,
body .tab_content_block .panel-group .panel-body .card .form_block .row .col-sm-12 {
  padding-left: 5px;
  padding-right: 5px;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group {
  margin-bottom: 20px;
  position: relative;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group.phone-input .form-control {
  padding-left: 50px;
  width: 100%;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group.phone-input .react-tel-input .flag-dropdown {
  background-color: #fff;
  border-radius: 7px 0 0 7px;
  border: 1px solid #ddd;
  z-index: 9;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .auto_complete_form {
  display: block;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-control {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 16px 20px;
  height: auto;
  font-size: 14px;
  font-weight: 400;
  border-radius: 7px;
  color: #555;
  height: 50px;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-control.auto_complete {
  padding: 0 7px;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-control.auto_complete input {
  font-size: 14px;
  margin-bottom: 0;
  color: #555;
  font-weight: 400;
  margin-top: -3px;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-control.auto_complete input input::-moz-placeholder {
  color: #555;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-control.auto_complete input input::placeholder {
  color: #555;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-control.auto_complete input input::-webkite-inline-placeholder {
  color: #555;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-control.auto_complete div.MuiAutocomplete-root.MuiPopper-root.MuiAutocomplete-popperDisable.portal.css-1rndd9j-MuiPopper-root-MuiAutocomplete-popper {
  font-size: 14px;
  margin-bottom: 0;
  color: #555;
  font-weight: 400;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-control.auto_complete .css-1q60rmi-MuiAutocomplete-endAdornment {
  top: calc(50% - 10px);
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-control .MuiSelect-select {
  padding: 0;
  font-style: normal;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-control fieldset {
  border-color: transparent;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-control .select_text {
  font-style: normal;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-control svg {
  right: 20px;
  color: #555;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-control.right_space {
  padding-right: 70px;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-control::-moz-placeholder {
  color: #555;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-control::placeholder {
  color: #555;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group textarea.form-control {
  height: auto;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-line {
  border: none;
  position: relative;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-line::after {
  border: none;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-line .form_control_title {
  position: absolute;
  right: 20px;
  top: 17px;
  font-size: 12px;
  color: #555;
  opacity: 0.7;
  background-color: #fff;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-line .custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 64px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400 !important;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-line .custom-file .custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 35px;
  margin: 0;
  opacity: 0;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-line .custom-file .custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: auto;
  padding: 20px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 400 !important;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-line .custom-file .custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .form-line .custom-file .custom-file-label::after {
  position: absolute;
  right: 20px;
  top: 14px;
  z-index: 3;
  display: block;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  content: "Browse";
  background-color: #0071C2;
  border-radius: 20px;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .btn-default {
  padding: 0;
  font-size: 14px;
  color: #555;
  border: none;
  background-color: transparent !important;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .bootstrap-select {
  border: 1px solid #ddd !important;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .bootstrap-select.btn-group .dropdown-toggle .filter-option {
  top: 0 !important;
  margin-left: 0;
}
body .tab_content_block .panel-group .panel-body .card .form_block .form-group .bootstrap-select.btn-group .dropdown-toggle .caret {
  margin-top: 2px;
}
body .tab_content_block .panel-group .panel-body .card .form_block p {
  font-size: 14px;
  font-weight: 400;
  color: #555;
  line-height: 24px;
  margin: 0 0 20px 0;
}
body .tab_content_block .panel-group .panel-body .card .form_block p .notes {
  font-size: 16px;
  font-weight: 500;
  font-family: "Atak Medium";
  color: #0071C2;
}
body .sidebar {
  top: 90px;
  left: 0;
  width: 110px;
  overflow: inherit;
  background-color: #FFF;
}
body .sidebar.top_leftsidebar {
  top: 0;
  height: 100%;
}
body .sidebar .menu {
  height: auto;
  overflow: inherit;
}
body .sidebar .menu .list li {
  border-bottom: 1px solid #ddd;
}
body .sidebar .menu .list li:hover {
  background-color: #fff !important;
}
body .sidebar .menu .list li a {
  padding: 10px;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  transition: all ease-in-out 0.5s;
  background-color: #fff;
}
body .sidebar .menu .list li.active > :first-child span {
  font-weight: 400;
}
body .sidebar .menu .list .custom-menu-item {
  position: relative;
}
body .sidebar .menu .list .custom-menu-item.active a {
  background-color: #E5F4FF;
}
body .sidebar .menu .list .custom-menu-item.active:first-child span {
  font-weight: 400;
}
body .sidebar .menu .list .custom-menu-item a {
  background-color: #fff;
}
body .sidebar .menu .list .custom-menu-item a i {
  margin-bottom: 2px;
}
body .sidebar .menu .list .custom-menu-item a i img {
  width: 35px;
}
body .sidebar .menu .list .custom-menu-item a span {
  font-size: 13px;
  font-weight: 400;
  color: #555;
  margin: 0;
}
body .sidebar .menu .list .custom-menu-item:hover .custom-sub-menu {
  transform: translate3d(0%, 0px, 0px);
  -webkit-transform: translate3d(0%, 0px, 0px);
  -ms-transform: translate3d(0%, 0px, 0px);
}
body .sidebar .menu .list .custom-menu-item .custom-sub-menu {
  top: 0;
  left: 100%;
  z-index: -1;
  width: 300px;
  padding: 15px 0;
  position: absolute;
  background: #fff;
  transform: translate3d(-100%, 0px, 0px);
  -ms-transform: translate3d(-100%, 0px, 0px);
  -webkit-transform: translate3d(-100%, 0px, 0px);
  transition: all 0.3s ease 0.3s;
  -webkit-transition: all 0.3s ease 0.3s;
  border: 1px solid #ddd;
}
body .sidebar .menu .list .custom-menu-item .custom-sub-menu h4 {
  gap: 0.4rem;
  display: flex;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 5px 0;
  align-items: center;
}
body .sidebar .menu .list .custom-menu-item .custom-sub-menu h4 img {
  width: 25px;
  margin-top: 0;
  margin-bottom: 5px;
  margin-right: 5px;
}
body .sidebar .menu .list .custom-menu-item .custom-sub-menu ul {
  padding: 0 15px;
  list-style-type: none;
}
body .sidebar .menu .list .custom-menu-item .custom-sub-menu ul li {
  padding: 2px 0;
}
body .sidebar .menu .list .custom-menu-item .custom-sub-menu ul li a {
  text-align: left;
  color: #555;
}
body .sidebar .menu .list .custom-menu-item .custom-sub-menu ul li a:hover {
  background-color: #E5F4FF;
}
body .sidebar .menu .list .custom-menu-item .custom-sub-menu ul li:last-child {
  padding: 2px 0;
  border-bottom: none;
}
body section.content {
  margin: 110px 30px 0 140px;
}
body section.content.full_page {
  margin: 0;
}
body .block-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
body .block-header h2 {
  font-size: 20px;
  font-weight: 700;
  color: #555;
  margin: auto 0 !important;
}
body .block-header .credit_name {
  display: inline-block;
}
body .block-header .credit_name p {
  font-size: 16px;
  font-weight: 400;
  color: #555;
  margin: 0;
  display: inline-block;
}
body .block-header .credit_name p.slace {
  margin: 0 10px;
}
body .block-header .credit_name p.blue {
  color: #0071C2;
}
body .block-header .link_block a.more_link {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #555;
  border: 1px solid #0071C2;
  background-color: #fff;
  border-radius: 50px;
  padding: 14px 18px;
  display: inline-block;
  margin-left: 15px;
}
body .block-header .link_block a.more_link:hover {
  background-color: #0071C2;
  color: #fff;
}
body .page_heading {
  padding: 30px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 20px;
  margin-bottom: 30px;
}
body .page_heading h3 {
  font-size: 25px;
  font-weight: 700;
  color: #0071C2;
  margin: auto 0;
}
body .page_heading a {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: block;
  background-color: #E5F4FF;
  padding: 15px;
}
body .page_heading a img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
body .main_class {
  padding: 30px;
  background-color: #fff;
  margin-bottom: 30px;
  border-radius: 20px;
}
body .main_class .button_block {
  padding: 0;
}
body .main_class .title_heading {
  font-size: 25px;
  font-weight: 700;
  color: #0071C2;
  font-family: "Helvetica Now Display Bold";
  margin: 0;
  margin-bottom: 30px;
}
body .main_class .pagination button {
  font-size: 14px !important;
  color: #555;
}
body .main_class .links {
  margin-left: 15px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  padding: 14px 18px;
  display: inline-block;
  border: 1px solid #0071C2;
  background-color: #fff;
  border-radius: 50px;
  text-transform: uppercase;
}
body .main_class .links.export {
  padding: 13px 18px;
}
body .main_class .links:hover {
  background-color: #0071C2;
  color: #fff;
}
body .main_class .requirement_set_block {
  padding: 25px;
  border: 2px dashed #0071C2;
  border-radius: 20px;
  transition: 0.2s;
  text-align: center;
}
body .main_class .requirement_set_block img {
  margin-bottom: 20px;
}
body .main_class .requirement_set_block .requirement_set_title {
  display: block;
  font-size: 20px;
  font-weight: 700;
  color: #0071C2;
  margin-bottom: 20px;
  font-family: "Helvetica Now Display Bold";
}
body .main_class .requirement_set_block .requirement_set_description {
  font-size: 16px;
  display: block;
  margin-bottom: 20px;
  color: #555;
}
body .main_class .requirement_set_block .links {
  background-color: #0071C2;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  font-family: "Helvetica Now Display Medium";
  border-radius: 30px;
  padding: 15px 18px;
  display: inline-block;
  text-transform: uppercase;
}
body .main_class .credit_balance_block {
  text-align: right;
  margin-bottom: 15px;
}
body .main_class .credit_balance_block .balance_block {
  border: 1px solid #ddd;
  border-radius: 5px;
  display: inline-block;
}
body .main_class .credit_balance_block .balance_block p {
  font-size: 14px;
  font-weight: 400;
  color: #555;
  margin: 0;
  display: inline-block;
  margin: 10px 0 10px 20px;
  padding-right: 20px;
}
body .main_class .credit_balance_block .balance_block p.balanced {
  border-right: 1px solid #ddd;
}
body .main_class .credit_balance_block .balance_block p img {
  margin-left: 10px;
}
body .main_class .credit_balance_block .balance_block button {
  padding: 9px 15px;
  border: none;
  border-left: 1px solid #ddd;
  border-radius: 0 6px 6px 0;
}
body .main_class .right_block {
  text-align: right;
}
body .main_class .property_image_block {
  padding: 0;
  list-style: none;
  margin-top: 20px;
  margin-bottom: 10px;
}
body .main_class .property_image_block .row {
  margin-left: -5px;
  margin-right: -5px;
}
body .main_class .property_image_block .row .col-md-3,
body .main_class .property_image_block .row .col-sm-4,
body .main_class .property_image_block .row .col-xs-6 {
  padding-left: 5px;
  padding-right: 5px;
}
body .main_class .property_image_block .property_image {
  position: relative;
  min-height: 1px;
  width: 100%;
  display: inline-block;
}
body .main_class .property_image_block .property_image.default {
  position: relative;
}
body .main_class .property_image_block .property_image.default .default_block {
  position: absolute;
  top: 10px;
  right: 5px;
}
body .main_class .property_image_block .property_image.default .default_block .default_image {
  padding: 5px 10px;
  background-color: #fff;
  text-align: center;
  border-radius: 5px;
  margin-left: 5px;
}
body .main_class .property_image_block .property_image.default .default_block .default_image img {
  width: 25px;
  height: 40px;
}
body .main_class .property_image_block .property_image.default .default_block .delete_image {
  width: 40px;
  height: 40px;
  background-color: #fff;
  text-align: center;
  border-radius: 5px;
  display: inline-block;
  margin-left: 5px;
}
body .main_class .property_image_block .property_image.default .default_block .delete_image img {
  width: 25px;
  height: 40px;
}
body .main_class .property_image_block .property_image.default .default_block .delete_image:hover {
  background-color: #EC1A25;
}
body .main_class .property_image_block .property_image.default .default_block .delete_image:hover img {
  filter: brightness(0) invert(1);
}
body .main_class .property_image_block .property_image img {
  max-width: 100%;
  width: 100%;
  height: 205px;
}
body .main_class p.note {
  font-size: 14px;
  font-weight: 400;
  color: #555;
  line-height: 24px;
  margin: 20px 0;
}
body .main_class p.note .notes {
  font-size: 16px;
  font-weight: 500;
  font-family: "Atak Medium";
  color: #0071C2;
}
body .main_class .note_block .note_person {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
body .main_class .note_block .note_person .note_person_image {
  display: flex;
  justify-content: space-between;
}
body .main_class .note_block .note_person .note_person_image img {
  border-radius: 5px;
  margin-right: 20px;
  display: inline-block;
}
body .main_class .note_block .note_person .note_person_image .person_detail {
  margin: auto 0;
}
body .main_class .note_block .note_person .note_person_image .person_detail h5 {
  font-size: 20px;
  font-weight: 700;
  color: #0071C2;
  font-family: "Helvetica Now Display Bold";
  margin: 0;
  margin-bottom: 5px;
}
body .main_class .note_block .note_person .note_person_image .person_detail p {
  font-size: 16px;
  font-weight: 400;
  color: #555;
  opacity: 0.7;
  margin: 0;
  line-height: 24px;
}
body .main_class .note_block .note_person .note_person_image p {
  font-size: 16px;
  font-weight: 400;
  color: #555;
  line-height: 24px;
}
body .main_class .note_block .note_person .note_time {
  margin: auto 0;
}
body .main_class .button_block {
  padding-top: 20px;
  text-align: right;
}
body .main_class .button_block .links {
  margin-left: 15px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  padding: 14px 18px;
  display: inline-block;
  border: 1px solid #0071C2;
  background-color: #fff;
  border-radius: 50px;
  text-transform: uppercase;
}
body .main_class .button_block .links:hover {
  background-color: #0071C2;
  color: #fff;
}
body .main_class .form_block.outline_form .form-group {
  margin-bottom: 20px;
  position: relative;
}
body .main_class .form_block.outline_form .form-group .form-line {
  border: none;
  position: relative;
}
body .main_class .form_block.outline_form .form-group .form-line .form-control {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 16px 20px;
  height: auto;
  font-size: 14px;
  font-weight: 400;
  border-radius: 7px;
  color: #555;
  height: 50px;
}
body .main_class .form_block .range_slider_block {
  height: 54px;
  margin-bottom: 10px;
  padding: 0 10px;
}
body .main_class .form_block .range_slider_block h6 {
  font-size: 12px;
  font-weight: 400;
  color: #555;
  margin: 0;
  margin-bottom: 5px;
  font-family: "Atak Regular";
}
body .main_class .form_block .range_slider_block .range_slider {
  padding: 8px 0;
  margin-bottom: -5px;
}
body .main_class .form_block .range_slider_block .css-eg0mwd-MuiSlider-thumb {
  width: 14px;
  height: 14px;
}
body .main_class .form_block .range_slider_block .css-eg0mwd-MuiSlider-thumb::after {
  height: 30px;
  width: 30px;
}
body .main_class .form_block .range_slider_block .left {
  font-size: 10px;
  font-weight: 400;
  color: #555;
  margin: 0;
}
body .main_class .form_block .range_slider_block .right {
  font-size: 10px;
  font-weight: 400;
  color: #555;
  margin: 0;
}
body .main_class .form_block .row {
  margin-left: -5px;
  margin-right: -5px;
}
body .main_class .form_block .row .col-md-3,
body .main_class .form_block .row .col-sm-4,
body .main_class .form_block .row .col-xs-6,
body .main_class .form_block .row .col-sm-6,
body .main_class .form_block .row .col-sm-12 {
  padding-left: 5px;
  padding-right: 5px;
}
body .main_class .form_block .form-group {
  margin-bottom: 10px;
}
body .main_class .form_block .form-group.notes {
  margin-bottom: 0;
}
body .main_class .form_block .form-group.notes .form-control {
  font-size: 16px;
  font-weight: 400;
  color: #555;
  padding: 0;
  height: auto;
  background-color: #fff;
}
body .main_class .form_block .form-group.notes .form-control::-moz-placeholder {
  color: #555;
  opacity: 1;
}
body .main_class .form_block .form-group.notes .form-control::placeholder {
  color: #555;
  opacity: 1;
}
body .main_class .form_block .form-group .form-control {
  background-color: #f5f5f5;
  border: none;
  padding: 16px 20px;
  height: auto;
  font-size: 16px;
  border-radius: 7px;
  color: #555;
  height: 54px;
  text-align: left;
  font-style: normal;
}
body .main_class .form_block .form-group .form-control.auto_complete {
  padding: 0;
}
body .main_class .form_block .form-group .form-control.auto_complete .css-igs3ac {
  border: none;
}
body .main_class .form_block .form-group .form-control.auto_complete input {
  font-size: 16px;
  margin-bottom: 0;
  color: #555;
  font-weight: 400;
  margin-top: -3px;
  padding: 9px 12px;
}
body .main_class .form_block .form-group .form-control.auto_complete input input::-moz-placeholder {
  color: #555;
}
body .main_class .form_block .form-group .form-control.auto_complete input input::placeholder {
  color: #555;
}
body .main_class .form_block .form-group .form-control.auto_complete input input::-webkite-inline-placeholder {
  color: #555;
}
body .main_class .form_block .form-group .form-control.auto_complete .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
}
body .main_class .form_block .form-group .form-control.auto_complete div.MuiAutocomplete-root.MuiPopper-root.MuiAutocomplete-popperDisable.portal.css-1rndd9j-MuiPopper-root-MuiAutocomplete-popper {
  font-size: 14px;
  margin-bottom: 0;
  color: #555;
  font-weight: 400;
}
body .main_class .form_block .form-group .form-control.auto_complete .css-1q60rmi-MuiAutocomplete-endAdornment {
  top: calc(50% - 10px);
  right: 20px;
  color: #555;
}
body .main_class .form_block .form-group .form-control.border {
  background-color: #fff;
  border: 1px solid #ddd;
}
body .main_class .form_block .form-group .form-control.border_temp {
  padding: 0;
}
body .main_class .form_block .form-group .form-control.border_temp .MuiSelect-select {
  padding: 16px 20px;
  font-style: normal;
}
body .main_class .form_block .form-group .form-control.border_temp fieldset {
  border-color: transparent;
}
body .main_class .form_block .form-group .form-control.border_temp .select_text {
  font-style: normal;
}
body .main_class .form_block .form-group .form-control.border_temp svg {
  right: 20px;
  color: #555;
}
body .main_class .form_block .form-group .form-control::-moz-placeholder {
  color: #555;
}
body .main_class .form_block .form-group .form-control::placeholder {
  color: #555;
}
body .main_class .form_block .form-group .form-line {
  border: none;
}
body .main_class .form_block .form-group .form-line .custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 64px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400 !important;
}
body .main_class .form_block .form-group .form-line .custom-file .custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 35px;
  margin: 0;
  opacity: 0;
}
body .main_class .form_block .form-group .form-line .custom-file .custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: auto;
  padding: 20px;
  line-height: 1.5;
  color: #495057;
  background-color: #f5f5f5;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 400 !important;
}
body .main_class .form_block .form-group .form-line .custom-file .custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
body .main_class .form_block .form-group .form-line .custom-file .custom-file-label::after {
  position: absolute;
  right: 20px;
  top: 14px;
  z-index: 3;
  display: block;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  content: "Browse";
  background-color: #0071C2;
  border-radius: 20px;
}
body .main_class .form_block .form-group .form-line::after {
  border: none;
}
body .main_class .form_block .form-group .btn-default {
  padding: 2px 0;
  font-size: 16px;
  color: #555;
  border: none;
  background-color: transparent !important;
}
body .main_class .form_block .form-group .bootstrap-select {
  border: none !important;
}
body .main_class .table_block {
  overflow: hidden;
}
body .main_class .table_block .form-group {
  margin-bottom: 0;
}
body .main_class .table_block table.dataTable {
  margin: 0 !important;
  border-top: 1px solid #ddd !important;
}
body .main_class .table_block table.dataTable.custom th,
body .main_class .table_block table.dataTable.custom td {
  text-align: center;
}
body .main_class .table_block table.dataTable th,
body .main_class .table_block table.dataTable td {
  text-align: left;
  vertical-align: middle;
}
body .main_class .table_block table.dataTable tfoot td {
  border-bottom: none;
}
body .main_class .table_block table.dataTable tr td {
  padding: 15px;
  line-height: normal;
}
body .main_class .table_block table.dataTable tr td table {
  width: 100%;
}
body .main_class .table_block table.dataTable tr td p {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
body .main_class .table_block table.dataTable tr td p.testimonial-msg {
  word-break: break-all;
}
body .main_class .table_block table.dataTable tr td p.word_break {
  white-space: nowrap;
}
body .main_class .table_block table.dataTable tr td .table_detail {
  position: relative;
}
body .main_class .table_block table.dataTable tr td .table_detail:hover .quality_score_detail {
  visibility: visible;
}
body .main_class .table_block table.dataTable tr td .table_detail.name h6 {
  white-space: normal;
  word-break: break-word;
}
body .main_class .table_block table.dataTable tr td .table_detail.name p {
  white-space: normal;
  word-break: break-word;
}
body .main_class .table_block table.dataTable tr td .table_detail .quality_score_detail {
  position: absolute;
  top: 45px;
  background: #555;
  color: #fff;
  padding: 15px;
  right: -10px;
  visibility: hidden;
  z-index: 205;
  border-radius: 4px;
}
body .main_class .table_block table.dataTable tr td .table_detail .quality_score_detail::after {
  width: 1em;
  height: 0.71em;
  box-sizing: border-box;
  background: #555;
  margin-top: -0.71em;
  position: absolute;
  right: 20px;
  transform: rotate(45deg);
  top: 7px;
  content: "";
  display: inline-block;
}
body .main_class .table_block table.dataTable tr td .table_detail .quality_score_detail .quality_tag {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
body .main_class .table_block table.dataTable tr td .table_detail .quality_score_detail .quality_tag:last-child {
  margin-bottom: 0;
}
body .main_class .table_block table.dataTable tr td .table_detail .quality_score_detail .quality_tag h6 {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  margin: 0;
  font-family: "Atak Regular";
  margin-right: 10px;
}
body .main_class .table_block table.dataTable tr td .table_detail .quality_score_detail .quality_tag p {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  margin: 0;
  opacity: 0.7;
}
body .main_class .table_block table.dataTable tr td .table_detail h6 {
  font-size: 14px;
  font-weight: 400;
  font-family: Atak Regular;
  margin: 0;
  margin-bottom: 5px;
  color: #555;
  white-space: nowrap;
}
body .main_class .table_block table.dataTable tr td .table_detail h6.blog-title {
  white-space: initial;
  word-break: break-all;
}
body .main_class .table_block table.dataTable tr td .table_detail h6.tooltip_notes .css-1k51tf5-MuiTooltip-tooltip {
  font-size: 14px;
  background-color: #fff;
  border: 1px solid black;
  color: #555;
}
body .main_class .table_block table.dataTable tr td .table_detail h6 img {
  margin-left: 3px;
}
body .main_class .table_block table.dataTable tr td .table_detail h6 .props {
  display: inline-block;
  margin-right: 5px;
  padding: 3px 5px;
  background: #ddd;
  border-radius: 5px;
}
body .main_class .table_block table.dataTable tr td .table_detail h6 .props:last-child {
  margin-right: 0;
}
body .main_class .table_block table.dataTable tr td .table_detail h6 .props img {
  margin-left: 5px;
}
body .main_class .table_block table.dataTable tr td .table_detail p {
  font-size: 12px;
  font-weight: 400;
  color: #555;
  margin: 0;
  margin-bottom: 5px;
  opacity: 0.7;
}
body .main_class .table_block table.dataTable tr td .table_detail p .props {
  display: inline-block;
  margin-right: 5px;
  padding: 3px 5px;
  background: #ddd;
  border-radius: 5px;
  opacity: 1 !important;
}
body .main_class .table_block table.dataTable tr td .table_detail p .props:last-child {
  margin-right: 0;
}
body .main_class .table_block table.dataTable tr td .table_detail p .props img {
  margin-left: 5px;
}
body .main_class .table_block table.dataTable tr td .table_detail a.status {
  padding: 5px 8px;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
}
body .main_class .table_block table.dataTable tr td .table_detail a.status.blue {
  background-color: #0071C2;
}
body .main_class .table_block table.dataTable tr td .table_detail a.status.green {
  background-color: #5EAC18;
}
body .main_class .table_block table.dataTable tr td .table_detail a.status.red {
  background-color: #EC1A25;
}
body .main_class .table_block table.dataTable tr td .table_detail span {
  font-size: 10px;
  font-weight: 400;
  color: #555;
  margin: 0;
}
body .main_class .table_block table.dataTable tr td .table_detail span.opac {
  opacity: 0.7;
  display: block;
  text-align: center;
}
body .main_class .table_block table.dataTable tr td .table_detail a.link {
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 5px;
}
body .main_class .table_block table.dataTable tr td .table_detail a.link.blue:hover {
  background-color: #0071C2;
  border: 1px solid #0071C2;
}
body .main_class .table_block table.dataTable tr td .table_detail a.link.blue:hover img {
  filter: brightness(0) invert(1);
}
body .main_class .table_block table.dataTable tr td .table_detail a.link.green:hover {
  background-color: #5EAC18;
  border: 1px solid #5EAC18;
}
body .main_class .table_block table.dataTable tr td .table_detail a.link.green:hover img {
  filter: brightness(0) invert(1);
}
body .main_class .table_block table.dataTable tr td .table_detail a.link.red:hover {
  background-color: #EC1A25;
  border: 1px solid #EC1A25;
}
body .main_class .table_block table.dataTable tr td .table_detail a.link.red:hover img {
  filter: brightness(0) invert(1);
}
body .main_class .table_block table.dataTable tr td .table_detail a.link:last-child {
  margin-bottom: 0;
}
body .main_class .table_block table.dataTable tr td .table_detail a.link img {
  width: 18px;
}
body .main_class .table_block table.dataTable tr td .table_detail .progress_bar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid #5EAC18;
  margin-left: 9px;
  margin-bottom: 5px;
}
body .main_class .table_block table.dataTable thead {
  background-color: #E5F4FF;
}
body .main_class .table_block table.dataTable thead th {
  font-size: 14px;
  font-weight: 700;
  font-family: "Helvetica Now Display Bold";
  color: #555;
  padding: 20px 15px;
}
body .main_class .table_block table.dataTable thead th .table_heading h5 {
  font-size: 14px;
  font-weight: 700;
  color: #555;
  margin: 0;
  margin-bottom: 5px;
}
body .main_class .table_block table.dataTable thead th .table_heading p {
  font-size: 10px;
  font-weight: 700;
  color: #555;
  margin: 0;
  opacity: 0.7;
}
body .main_class .table_block table.dataTable tfoot {
  background-color: #E5F4FF;
}
body .main_class .table_block table.dataTable tfoot th {
  font-size: 14px;
  font-weight: 700;
  font-family: "Helvetica Now Display Bold";
  color: #555;
  padding: 20px 15px;
  border-bottom: none;
}
body .main_class .table_block table.dataTable tfoot th .table_heading h5 {
  font-size: 14px;
  font-weight: 700;
  color: #555;
  margin: 0;
  margin-bottom: 5px;
}
body .main_class .table_block table.dataTable tfoot th .table_heading p {
  font-size: 10px;
  font-weight: 700;
  color: #555;
  margin: 0;
  opacity: 0.7;
}
body .main_class .table_block .card {
  box-shadow: none;
  margin-bottom: 0;
}
body .main_class .table_block .card .icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  background-color: #555;
  border-radius: 50%;
  padding: 5px;
}
body .main_class .table_block .input-group {
  margin-bottom: 0;
}

.selected_amenities {
  padding: 0;
}
.selected_amenities ul {
  margin: 0;
  padding: 0;
}
.selected_amenities ul li {
  margin-right: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  color: #555;
  margin-bottom: 5px;
  display: inline-block;
  list-style-type: none;
  background-color: #f5f5f5;
}

.form-group.icon_field {
  position: relative;
}
.form-group.icon_field .eye_icon {
  top: 15px;
  right: 15px;
  position: absolute;
}

.custom-bargraph .recharts-rectangle {
  height: 50px;
}/*# sourceMappingURL=custom.css.map */