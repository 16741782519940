.box {

    height: 420px;
    background-color: white;
    border-radius: 20px;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(226, 6, 6, 0.1);
    margin: auto;
    width: auto;
}

.pie_chart_block {
    padding: 10px;
}

.pie-box {

    background-color: white;

}

.box-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    border-bottom: 2px solid #ECECEC;

}

.title {
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    font-family: 'Helvetica Now Display Bold';
    margin-left: 20px;
}

.tabs {
    display: flex;
}

.tab {
    margin-left: 10px;
    cursor: pointer;
}

.box-content {
    display: flex;
    justify-content: center;
    align-items: center;


}