.theme-red .navbar {
  background-color: #F44336; }

.theme-red .navbar-brand {
  color: #fff; }
  .theme-red .navbar-brand:hover {
    color: #fff; }
  .theme-red .navbar-brand:active {
    color: #fff; }
  .theme-red .navbar-brand:focus {
    color: #fff; }

.theme-red .nav > li > a {
  color: #fff; }
  .theme-red .nav > li > a:hover {
    background-color: transparent; }
  .theme-red .nav > li > a:focus {
    background-color: transparent; }

.theme-red .nav .open > a {
  background-color: transparent; }
  .theme-red .nav .open > a:hover {
    background-color: transparent; }
  .theme-red .nav .open > a:focus {
    background-color: transparent; }

.theme-red .bars {
  color: #fff; }

.theme-red .sidebar .menu .list li.active {
  background-color: transparent; }
  .theme-red .sidebar .menu .list li.active > :first-child i, .theme-red .sidebar .menu .list li.active > :first-child span {
    color: #F44336; }

.theme-red .sidebar .menu .list .toggled {
  background-color: transparent; }

.theme-red .sidebar .menu .list .ml-menu {
  background-color: transparent; }

.theme-red .sidebar .legal {
  background-color: #fff; }
  .theme-red .sidebar .legal .copyright a {
    color: #F44336 !important; }

.theme-pink .navbar {
  background-color: #E91E63; }

.theme-pink .navbar-brand {
  color: #fff; }
  .theme-pink .navbar-brand:hover {
    color: #fff; }
  .theme-pink .navbar-brand:active {
    color: #fff; }
  .theme-pink .navbar-brand:focus {
    color: #fff; }

.theme-pink .nav > li > a {
  color: #fff; }
  .theme-pink .nav > li > a:hover {
    background-color: transparent; }
  .theme-pink .nav > li > a:focus {
    background-color: transparent; }

.theme-pink .nav .open > a {
  background-color: transparent; }
  .theme-pink .nav .open > a:hover {
    background-color: transparent; }
  .theme-pink .nav .open > a:focus {
    background-color: transparent; }

.theme-pink .bars {
  color: #fff; }

.theme-pink .sidebar .menu .list li.active {
  background-color: transparent; }
  .theme-pink .sidebar .menu .list li.active > :first-child i, .theme-pink .sidebar .menu .list li.active > :first-child span {
    color: #E91E63; }

.theme-pink .sidebar .menu .list .toggled {
  background-color: transparent; }

.theme-pink .sidebar .menu .list .ml-menu {
  background-color: transparent; }

.theme-pink .sidebar .legal {
  background-color: #fff; }
  .theme-pink .sidebar .legal .copyright a {
    color: #E91E63 !important; }

.theme-purple .navbar {
  background-color: #9C27B0; }

.theme-purple .navbar-brand {
  color: #fff; }
  .theme-purple .navbar-brand:hover {
    color: #fff; }
  .theme-purple .navbar-brand:active {
    color: #fff; }
  .theme-purple .navbar-brand:focus {
    color: #fff; }

.theme-purple .nav > li > a {
  color: #fff; }
  .theme-purple .nav > li > a:hover {
    background-color: transparent; }
  .theme-purple .nav > li > a:focus {
    background-color: transparent; }

.theme-purple .nav .open > a {
  background-color: transparent; }
  .theme-purple .nav .open > a:hover {
    background-color: transparent; }
  .theme-purple .nav .open > a:focus {
    background-color: transparent; }

.theme-purple .bars {
  color: #fff; }

.theme-purple .sidebar .menu .list li.active {
  background-color: transparent; }
  .theme-purple .sidebar .menu .list li.active > :first-child i, .theme-purple .sidebar .menu .list li.active > :first-child span {
    color: #9C27B0; }

.theme-purple .sidebar .menu .list .toggled {
  background-color: transparent; }

.theme-purple .sidebar .menu .list .ml-menu {
  background-color: transparent; }

.theme-purple .sidebar .legal {
  background-color: #fff; }
  .theme-purple .sidebar .legal .copyright a {
    color: #9C27B0 !important; }

.theme-deep-purple .navbar {
  background-color: #673AB7; }

.theme-deep-purple .navbar-brand {
  color: #fff; }
  .theme-deep-purple .navbar-brand:hover {
    color: #fff; }
  .theme-deep-purple .navbar-brand:active {
    color: #fff; }
  .theme-deep-purple .navbar-brand:focus {
    color: #fff; }

.theme-deep-purple .nav > li > a {
  color: #fff; }
  .theme-deep-purple .nav > li > a:hover {
    background-color: transparent; }
  .theme-deep-purple .nav > li > a:focus {
    background-color: transparent; }

.theme-deep-purple .nav .open > a {
  background-color: transparent; }
  .theme-deep-purple .nav .open > a:hover {
    background-color: transparent; }
  .theme-deep-purple .nav .open > a:focus {
    background-color: transparent; }

.theme-deep-purple .bars {
  color: #fff; }

.theme-deep-purple .sidebar .menu .list li.active {
  background-color: transparent; }
  .theme-deep-purple .sidebar .menu .list li.active > :first-child i, .theme-deep-purple .sidebar .menu .list li.active > :first-child span {
    color: #673AB7; }

.theme-deep-purple .sidebar .menu .list .toggled {
  background-color: transparent; }

.theme-deep-purple .sidebar .menu .list .ml-menu {
  background-color: transparent; }

.theme-deep-purple .sidebar .legal {
  background-color: #fff; }
  .theme-deep-purple .sidebar .legal .copyright a {
    color: #673AB7 !important; }

.theme-indigo .navbar {
  background-color: #3F51B5; }

.theme-indigo .navbar-brand {
  color: #fff; }
  .theme-indigo .navbar-brand:hover {
    color: #fff; }
  .theme-indigo .navbar-brand:active {
    color: #fff; }
  .theme-indigo .navbar-brand:focus {
    color: #fff; }

.theme-indigo .nav > li > a {
  color: #fff; }
  .theme-indigo .nav > li > a:hover {
    background-color: transparent; }
  .theme-indigo .nav > li > a:focus {
    background-color: transparent; }

.theme-indigo .nav .open > a {
  background-color: transparent; }
  .theme-indigo .nav .open > a:hover {
    background-color: transparent; }
  .theme-indigo .nav .open > a:focus {
    background-color: transparent; }

.theme-indigo .bars {
  color: #fff; }

.theme-indigo .sidebar .menu .list li.active {
  background-color: transparent; }
  .theme-indigo .sidebar .menu .list li.active > :first-child i, .theme-indigo .sidebar .menu .list li.active > :first-child span {
    color: #3F51B5; }

.theme-indigo .sidebar .menu .list .toggled {
  background-color: transparent; }

.theme-indigo .sidebar .menu .list .ml-menu {
  background-color: transparent; }

.theme-indigo .sidebar .legal {
  background-color: #fff; }
  .theme-indigo .sidebar .legal .copyright a {
    color: #3F51B5 !important; }

.theme-blue .navbar {
  background-color: #2196F3; }

.theme-blue .navbar-brand {
  color: #fff; }
  .theme-blue .navbar-brand:hover {
    color: #fff; }
  .theme-blue .navbar-brand:active {
    color: #fff; }
  .theme-blue .navbar-brand:focus {
    color: #fff; }

.theme-blue .nav > li > a {
  color: #fff; }
  .theme-blue .nav > li > a:hover {
    background-color: transparent; }
  .theme-blue .nav > li > a:focus {
    background-color: transparent; }

.theme-blue .nav .open > a {
  background-color: transparent; }
  .theme-blue .nav .open > a:hover {
    background-color: transparent; }
  .theme-blue .nav .open > a:focus {
    background-color: transparent; }

.theme-blue .bars {
  color: #fff; }

.theme-blue .sidebar .menu .list li.active {
  background-color: transparent; }
  .theme-blue .sidebar .menu .list li.active > :first-child i, .theme-blue .sidebar .menu .list li.active > :first-child span {
    color: #2196F3; }

.theme-blue .sidebar .menu .list .toggled {
  background-color: transparent; }

.theme-blue .sidebar .menu .list .ml-menu {
  background-color: transparent; }

.theme-blue .sidebar .legal {
  background-color: #fff; }
  .theme-blue .sidebar .legal .copyright a {
    color: #2196F3 !important; }

.theme-light-blue .navbar {
  background-color: #03A9F4; }

.theme-light-blue .navbar-brand {
  color: #fff; }
  .theme-light-blue .navbar-brand:hover {
    color: #fff; }
  .theme-light-blue .navbar-brand:active {
    color: #fff; }
  .theme-light-blue .navbar-brand:focus {
    color: #fff; }

.theme-light-blue .nav > li > a {
  color: #fff; }
  .theme-light-blue .nav > li > a:hover {
    background-color: transparent; }
  .theme-light-blue .nav > li > a:focus {
    background-color: transparent; }

.theme-light-blue .nav .open > a {
  background-color: transparent; }
  .theme-light-blue .nav .open > a:hover {
    background-color: transparent; }
  .theme-light-blue .nav .open > a:focus {
    background-color: transparent; }

.theme-light-blue .bars {
  color: #fff; }

.theme-light-blue .sidebar .menu .list li.active {
  background-color: transparent; }
  .theme-light-blue .sidebar .menu .list li.active > :first-child i, .theme-light-blue .sidebar .menu .list li.active > :first-child span {
    color: #03A9F4; }

.theme-light-blue .sidebar .menu .list .toggled {
  background-color: transparent; }

.theme-light-blue .sidebar .menu .list .ml-menu {
  background-color: transparent; }

.theme-light-blue .sidebar .legal {
  background-color: #fff; }
  .theme-light-blue .sidebar .legal .copyright a {
    color: #03A9F4 !important; }

.theme-cyan .navbar {
  background-color: #00BCD4; }

.theme-cyan .navbar-brand {
  color: #fff; }
  .theme-cyan .navbar-brand:hover {
    color: #fff; }
  .theme-cyan .navbar-brand:active {
    color: #fff; }
  .theme-cyan .navbar-brand:focus {
    color: #fff; }

.theme-cyan .nav > li > a {
  color: #fff; }
  .theme-cyan .nav > li > a:hover {
    background-color: transparent; }
  .theme-cyan .nav > li > a:focus {
    background-color: transparent; }

.theme-cyan .nav .open > a {
  background-color: transparent; }
  .theme-cyan .nav .open > a:hover {
    background-color: transparent; }
  .theme-cyan .nav .open > a:focus {
    background-color: transparent; }

.theme-cyan .bars {
  color: #fff; }

.theme-cyan .sidebar .menu .list li.active {
  background-color: transparent; }
  .theme-cyan .sidebar .menu .list li.active > :first-child i, .theme-cyan .sidebar .menu .list li.active > :first-child span {
    color: #00BCD4; }

.theme-cyan .sidebar .menu .list .toggled {
  background-color: transparent; }

.theme-cyan .sidebar .menu .list .ml-menu {
  background-color: transparent; }

.theme-cyan .sidebar .legal {
  background-color: #fff; }
  .theme-cyan .sidebar .legal .copyright a {
    color: #00BCD4 !important; }

.theme-teal .navbar {
  background-color: #009688; }

.theme-teal .navbar-brand {
  color: #fff; }
  .theme-teal .navbar-brand:hover {
    color: #fff; }
  .theme-teal .navbar-brand:active {
    color: #fff; }
  .theme-teal .navbar-brand:focus {
    color: #fff; }

.theme-teal .nav > li > a {
  color: #fff; }
  .theme-teal .nav > li > a:hover {
    background-color: transparent; }
  .theme-teal .nav > li > a:focus {
    background-color: transparent; }

.theme-teal .nav .open > a {
  background-color: transparent; }
  .theme-teal .nav .open > a:hover {
    background-color: transparent; }
  .theme-teal .nav .open > a:focus {
    background-color: transparent; }

.theme-teal .bars {
  color: #fff; }

.theme-teal .sidebar .menu .list li.active {
  background-color: transparent; }
  .theme-teal .sidebar .menu .list li.active > :first-child i, .theme-teal .sidebar .menu .list li.active > :first-child span {
    color: #009688; }

.theme-teal .sidebar .menu .list .toggled {
  background-color: transparent; }

.theme-teal .sidebar .menu .list .ml-menu {
  background-color: transparent; }

.theme-teal .sidebar .legal {
  background-color: #fff; }
  .theme-teal .sidebar .legal .copyright a {
    color: #009688 !important; }

.theme-green .navbar {
  background-color: #4CAF50; }

.theme-green .navbar-brand {
  color: #fff; }
  .theme-green .navbar-brand:hover {
    color: #fff; }
  .theme-green .navbar-brand:active {
    color: #fff; }
  .theme-green .navbar-brand:focus {
    color: #fff; }

.theme-green .nav > li > a {
  color: #fff; }
  .theme-green .nav > li > a:hover {
    background-color: transparent; }
  .theme-green .nav > li > a:focus {
    background-color: transparent; }

.theme-green .nav .open > a {
  background-color: transparent; }
  .theme-green .nav .open > a:hover {
    background-color: transparent; }
  .theme-green .nav .open > a:focus {
    background-color: transparent; }

.theme-green .bars {
  color: #fff; }

.theme-green .sidebar .menu .list li.active {
  background-color: transparent; }
  .theme-green .sidebar .menu .list li.active > :first-child i, .theme-green .sidebar .menu .list li.active > :first-child span {
    color: #4CAF50; }

.theme-green .sidebar .menu .list .toggled {
  background-color: transparent; }

.theme-green .sidebar .menu .list .ml-menu {
  background-color: transparent; }

.theme-green .sidebar .legal {
  background-color: #fff; }
  .theme-green .sidebar .legal .copyright a {
    color: #4CAF50 !important; }

.theme-light-green .navbar {
  background-color: #8BC34A; }

.theme-light-green .navbar-brand {
  color: #fff; }
  .theme-light-green .navbar-brand:hover {
    color: #fff; }
  .theme-light-green .navbar-brand:active {
    color: #fff; }
  .theme-light-green .navbar-brand:focus {
    color: #fff; }

.theme-light-green .nav > li > a {
  color: #fff; }
  .theme-light-green .nav > li > a:hover {
    background-color: transparent; }
  .theme-light-green .nav > li > a:focus {
    background-color: transparent; }

.theme-light-green .nav .open > a {
  background-color: transparent; }
  .theme-light-green .nav .open > a:hover {
    background-color: transparent; }
  .theme-light-green .nav .open > a:focus {
    background-color: transparent; }

.theme-light-green .bars {
  color: #fff; }

.theme-light-green .sidebar .menu .list li.active {
  background-color: transparent; }
  .theme-light-green .sidebar .menu .list li.active > :first-child i, .theme-light-green .sidebar .menu .list li.active > :first-child span {
    color: #8BC34A; }

.theme-light-green .sidebar .menu .list .toggled {
  background-color: transparent; }

.theme-light-green .sidebar .menu .list .ml-menu {
  background-color: transparent; }

.theme-light-green .sidebar .legal {
  background-color: #fff; }
  .theme-light-green .sidebar .legal .copyright a {
    color: #8BC34A !important; }

.theme-lime .navbar {
  background-color: #CDDC39; }

.theme-lime .navbar-brand {
  color: #fff; }
  .theme-lime .navbar-brand:hover {
    color: #fff; }
  .theme-lime .navbar-brand:active {
    color: #fff; }
  .theme-lime .navbar-brand:focus {
    color: #fff; }

.theme-lime .nav > li > a {
  color: #fff; }
  .theme-lime .nav > li > a:hover {
    background-color: transparent; }
  .theme-lime .nav > li > a:focus {
    background-color: transparent; }

.theme-lime .nav .open > a {
  background-color: transparent; }
  .theme-lime .nav .open > a:hover {
    background-color: transparent; }
  .theme-lime .nav .open > a:focus {
    background-color: transparent; }

.theme-lime .bars {
  color: #fff; }

.theme-lime .sidebar .menu .list li.active {
  background-color: transparent; }
  .theme-lime .sidebar .menu .list li.active > :first-child i, .theme-lime .sidebar .menu .list li.active > :first-child span {
    color: #CDDC39; }

.theme-lime .sidebar .menu .list .toggled {
  background-color: transparent; }

.theme-lime .sidebar .menu .list .ml-menu {
  background-color: transparent; }

.theme-lime .sidebar .legal {
  background-color: #fff; }
  .theme-lime .sidebar .legal .copyright a {
    color: #CDDC39 !important; }

.theme-yellow .navbar {
  background-color: #FFEB3B; }

.theme-yellow .navbar-brand {
  color: #fff; }
  .theme-yellow .navbar-brand:hover {
    color: #fff; }
  .theme-yellow .navbar-brand:active {
    color: #fff; }
  .theme-yellow .navbar-brand:focus {
    color: #fff; }

.theme-yellow .nav > li > a {
  color: #fff; }
  .theme-yellow .nav > li > a:hover {
    background-color: transparent; }
  .theme-yellow .nav > li > a:focus {
    background-color: transparent; }

.theme-yellow .nav .open > a {
  background-color: transparent; }
  .theme-yellow .nav .open > a:hover {
    background-color: transparent; }
  .theme-yellow .nav .open > a:focus {
    background-color: transparent; }

.theme-yellow .bars {
  color: #fff; }

.theme-yellow .sidebar .menu .list li.active {
  background-color: transparent; }
  .theme-yellow .sidebar .menu .list li.active > :first-child i, .theme-yellow .sidebar .menu .list li.active > :first-child span {
    color: #FFEB3B; }

.theme-yellow .sidebar .menu .list .toggled {
  background-color: transparent; }

.theme-yellow .sidebar .menu .list .ml-menu {
  background-color: transparent; }

.theme-yellow .sidebar .legal {
  background-color: #fff; }
  .theme-yellow .sidebar .legal .copyright a {
    color: #FFEB3B !important; }

.theme-amber .navbar {
  background-color: #FFC107; }

.theme-amber .navbar-brand {
  color: #fff; }
  .theme-amber .navbar-brand:hover {
    color: #fff; }
  .theme-amber .navbar-brand:active {
    color: #fff; }
  .theme-amber .navbar-brand:focus {
    color: #fff; }

.theme-amber .nav > li > a {
  color: #fff; }
  .theme-amber .nav > li > a:hover {
    background-color: transparent; }
  .theme-amber .nav > li > a:focus {
    background-color: transparent; }

.theme-amber .nav .open > a {
  background-color: transparent; }
  .theme-amber .nav .open > a:hover {
    background-color: transparent; }
  .theme-amber .nav .open > a:focus {
    background-color: transparent; }

.theme-amber .bars {
  color: #fff; }

.theme-amber .sidebar .menu .list li.active {
  background-color: transparent; }
  .theme-amber .sidebar .menu .list li.active > :first-child i, .theme-amber .sidebar .menu .list li.active > :first-child span {
    color: #FFC107; }

.theme-amber .sidebar .menu .list .toggled {
  background-color: transparent; }

.theme-amber .sidebar .menu .list .ml-menu {
  background-color: transparent; }

.theme-amber .sidebar .legal {
  background-color: #fff; }
  .theme-amber .sidebar .legal .copyright a {
    color: #FFC107 !important; }

.theme-orange .navbar {
  background-color: #FF9800; }

.theme-orange .navbar-brand {
  color: #fff; }
  .theme-orange .navbar-brand:hover {
    color: #fff; }
  .theme-orange .navbar-brand:active {
    color: #fff; }
  .theme-orange .navbar-brand:focus {
    color: #fff; }

.theme-orange .nav > li > a {
  color: #fff; }
  .theme-orange .nav > li > a:hover {
    background-color: transparent; }
  .theme-orange .nav > li > a:focus {
    background-color: transparent; }

.theme-orange .nav .open > a {
  background-color: transparent; }
  .theme-orange .nav .open > a:hover {
    background-color: transparent; }
  .theme-orange .nav .open > a:focus {
    background-color: transparent; }

.theme-orange .bars {
  color: #fff; }

.theme-orange .sidebar .menu .list li.active {
  background-color: transparent; }
  .theme-orange .sidebar .menu .list li.active > :first-child i, .theme-orange .sidebar .menu .list li.active > :first-child span {
    color: #FF9800; }

.theme-orange .sidebar .menu .list .toggled {
  background-color: transparent; }

.theme-orange .sidebar .menu .list .ml-menu {
  background-color: transparent; }

.theme-orange .sidebar .legal {
  background-color: #fff; }
  .theme-orange .sidebar .legal .copyright a {
    color: #FF9800 !important; }

.theme-deep-orange .navbar {
  background-color: #FF5722; }

.theme-deep-orange .navbar-brand {
  color: #fff; }
  .theme-deep-orange .navbar-brand:hover {
    color: #fff; }
  .theme-deep-orange .navbar-brand:active {
    color: #fff; }
  .theme-deep-orange .navbar-brand:focus {
    color: #fff; }

.theme-deep-orange .nav > li > a {
  color: #fff; }
  .theme-deep-orange .nav > li > a:hover {
    background-color: transparent; }
  .theme-deep-orange .nav > li > a:focus {
    background-color: transparent; }

.theme-deep-orange .nav .open > a {
  background-color: transparent; }
  .theme-deep-orange .nav .open > a:hover {
    background-color: transparent; }
  .theme-deep-orange .nav .open > a:focus {
    background-color: transparent; }

.theme-deep-orange .bars {
  color: #fff; }

.theme-deep-orange .sidebar .menu .list li.active {
  background-color: transparent; }
  .theme-deep-orange .sidebar .menu .list li.active > :first-child i, .theme-deep-orange .sidebar .menu .list li.active > :first-child span {
    color: #FF5722; }

.theme-deep-orange .sidebar .menu .list .toggled {
  background-color: transparent; }

.theme-deep-orange .sidebar .menu .list .ml-menu {
  background-color: transparent; }

.theme-deep-orange .sidebar .legal {
  background-color: #fff; }
  .theme-deep-orange .sidebar .legal .copyright a {
    color: #FF5722 !important; }

.theme-brown .navbar {
  background-color: #795548; }

.theme-brown .navbar-brand {
  color: #fff; }
  .theme-brown .navbar-brand:hover {
    color: #fff; }
  .theme-brown .navbar-brand:active {
    color: #fff; }
  .theme-brown .navbar-brand:focus {
    color: #fff; }

.theme-brown .nav > li > a {
  color: #fff; }
  .theme-brown .nav > li > a:hover {
    background-color: transparent; }
  .theme-brown .nav > li > a:focus {
    background-color: transparent; }

.theme-brown .nav .open > a {
  background-color: transparent; }
  .theme-brown .nav .open > a:hover {
    background-color: transparent; }
  .theme-brown .nav .open > a:focus {
    background-color: transparent; }

.theme-brown .bars {
  color: #fff; }

.theme-brown .sidebar .menu .list li.active {
  background-color: transparent; }
  .theme-brown .sidebar .menu .list li.active > :first-child i, .theme-brown .sidebar .menu .list li.active > :first-child span {
    color: #795548; }

.theme-brown .sidebar .menu .list .toggled {
  background-color: transparent; }

.theme-brown .sidebar .menu .list .ml-menu {
  background-color: transparent; }

.theme-brown .sidebar .legal {
  background-color: #fff; }
  .theme-brown .sidebar .legal .copyright a {
    color: #795548 !important; }

.theme-grey .navbar {
  background-color: #9E9E9E; }

.theme-grey .navbar-brand {
  color: #fff; }
  .theme-grey .navbar-brand:hover {
    color: #fff; }
  .theme-grey .navbar-brand:active {
    color: #fff; }
  .theme-grey .navbar-brand:focus {
    color: #fff; }

.theme-grey .nav > li > a {
  color: #fff; }
  .theme-grey .nav > li > a:hover {
    background-color: transparent; }
  .theme-grey .nav > li > a:focus {
    background-color: transparent; }

.theme-grey .nav .open > a {
  background-color: transparent; }
  .theme-grey .nav .open > a:hover {
    background-color: transparent; }
  .theme-grey .nav .open > a:focus {
    background-color: transparent; }

.theme-grey .bars {
  color: #fff; }

.theme-grey .sidebar .menu .list li.active {
  background-color: transparent; }
  .theme-grey .sidebar .menu .list li.active > :first-child i, .theme-grey .sidebar .menu .list li.active > :first-child span {
    color: #9E9E9E; }

.theme-grey .sidebar .menu .list .toggled {
  background-color: transparent; }

.theme-grey .sidebar .menu .list .ml-menu {
  background-color: transparent; }

.theme-grey .sidebar .legal {
  background-color: #fff; }
  .theme-grey .sidebar .legal .copyright a {
    color: #9E9E9E !important; }

.theme-blue-grey .navbar {
  background-color: #607D8B; }

.theme-blue-grey .navbar-brand {
  color: #fff; }
  .theme-blue-grey .navbar-brand:hover {
    color: #fff; }
  .theme-blue-grey .navbar-brand:active {
    color: #fff; }
  .theme-blue-grey .navbar-brand:focus {
    color: #fff; }

.theme-blue-grey .nav > li > a {
  color: #fff; }
  .theme-blue-grey .nav > li > a:hover {
    background-color: transparent; }
  .theme-blue-grey .nav > li > a:focus {
    background-color: transparent; }

.theme-blue-grey .nav .open > a {
  background-color: transparent; }
  .theme-blue-grey .nav .open > a:hover {
    background-color: transparent; }
  .theme-blue-grey .nav .open > a:focus {
    background-color: transparent; }

.theme-blue-grey .bars {
  color: #fff; }

.theme-blue-grey .sidebar .menu .list li.active {
  background-color: transparent; }
  .theme-blue-grey .sidebar .menu .list li.active > :first-child i, .theme-blue-grey .sidebar .menu .list li.active > :first-child span {
    color: #607D8B; }

.theme-blue-grey .sidebar .menu .list .toggled {
  background-color: transparent; }

.theme-blue-grey .sidebar .menu .list .ml-menu {
  background-color: transparent; }

.theme-blue-grey .sidebar .legal {
  background-color: #fff; }
  .theme-blue-grey .sidebar .legal .copyright a {
    color: #607D8B !important; }

.theme-black .navbar {
  background-color: #000; }

.theme-black .navbar-brand {
  color: #fff; }
  .theme-black .navbar-brand:hover {
    color: #fff; }
  .theme-black .navbar-brand:active {
    color: #fff; }
  .theme-black .navbar-brand:focus {
    color: #fff; }

.theme-black .nav > li > a {
  color: #fff; }
  .theme-black .nav > li > a:hover {
    background-color: transparent; }
  .theme-black .nav > li > a:focus {
    background-color: transparent; }

.theme-black .nav .open > a {
  background-color: transparent; }
  .theme-black .nav .open > a:hover {
    background-color: transparent; }
  .theme-black .nav .open > a:focus {
    background-color: transparent; }

.theme-black .bars {
  color: #fff; }

.theme-black .sidebar .menu .list li.active {
  background-color: transparent; }
  .theme-black .sidebar .menu .list li.active > :first-child i, .theme-black .sidebar .menu .list li.active > :first-child span {
    color: #000; }

.theme-black .sidebar .menu .list .toggled {
  background-color: transparent; }

.theme-black .sidebar .menu .list .ml-menu {
  background-color: transparent; }

.theme-black .sidebar .legal {
  background-color: #fff; }
  .theme-black .sidebar .legal .copyright a {
    color: #000 !important; }

